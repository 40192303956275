import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Input, Renderer2, EventEmitter, Output } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';
import { forkJoin } from 'rxjs';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project } from 'src/app/buisness-object/project/Project';


@Component({
  selector: 'app-customer-ticket-create',
  templateUrl: './customer-ticket-create.component.html',
  styleUrls: ['./customer-ticket-create.component.scss']
})
export class CustomerTicketCreateComponent implements OnInit {
  @Input() users!: User[];
  @Input() customer: Customer;
  @Input() project: Project;
  @Input() routeCustomerId!: number;
  @Output() closeCreateSuccessEmitter = new EventEmitter<Thread>();
  @Output() closeCreateAndResolveEmitter = new EventEmitter<Thread>();
  @Output() selectionBackEmitter = new EventEmitter<undefined>();
  public filterdUsers: User[] = [];
  public previousText = '';
  public showDropDownUser = false;
  public posTopUserBox = 0;
  public posLeftUserBox = 0;
  public lastInputPos = 0;
  public listenerFn!: () => void;
  public listenerArrowkeys!: () => void;
  public userArrowIndex = 0;
  public amountOfAddedUser = 0;
  public placeArrowIndex = -1;
  @ViewChild('textinput', {static: false}) textInput!: ElementRef;
  public form!: FormGroup;
  public submitted = false;
  public showDropdownOne = false;
  @ViewChild('inputCustomer') inputCustomer! : ElementRef;
  @ViewChild('dropdown_1') dropdown_1! : ElementRef;
  public listener!: () => void;


  constructor(
    private formBuilder: FormBuilder,
    private tService: ThreadService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      customer: [null,Validators.required],
      thread_typ: [1,Validators.required],
    })
    if(this.customer){
      this.form.controls['customer'].setValue(this.customer);
    }
  }

  ngAfterViewInit(): void {
    if(this.inputCustomer && this.routeCustomerId) this.inputCustomer.nativeElement.value = this.form.controls['customer'].value.company_name;
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
  }

  createThread(message: string) {
    this.submitted = true;
    if(this.form.invalid) return;
    let thread = new Thread(
      0,
      this.form.controls['customer'].value.customer_id,
      Number(localStorage.getItem('user_id')),
      this.form.controls['thread_typ'].value,
      1,
      false,
      false, //notification
      Date.now(),
      0,
      0,
    );
    thread.customer = this.form.controls['customer'].value;
    thread.project_id = this.project ? this.project.project_id : null;
    thread.messages.push(new Message(
      0,
      0,
      thread.userId,
      message,
      Date.now()
    ));
    this.tService.createThread(thread).subscribe((result) => {
      if(result){
        result.customer = thread.customer;
        for(let user of this.users){
          if(result.userId == user.userId){
            result.user = user;
            if(result.messages?.length > 0){
              result.messages[0].user = user;
            }
            break;
          }
        }
        this.closeCreateSuccessEmitter.emit(result);
      }
    });
  }
}
