import { LoginService } from './../../service/login/login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  public form!: FormGroup;
  public submitted = false;
  public accessDenied = false;
  public showPassword = false;
  public showForgotPassword = false;
  public version!: string;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if(localStorage.getItem('token') != null){
      this.router.navigate(['/customers']);
    }
    this.version = environment.VERSION;
    this.form = this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null,[Validators.required]],
    })
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.loginService.login(this.form.controls['email'].value, this.form.controls['password'].value).subscribe((success) => {
      if(success){
        this.router.navigate(['/customers']);
      } else {
        this.accessDenied = true;
        this.submitted = false;
      }
    });
  }

  forgotPasswordAction() {
    this.form.reset();
    this.submitted = false;
    this.accessDenied = false;
    this.showForgotPassword = true;
  }

  openWebsite() {
    window.open('https://software-entwicklung-graz.at/', '_blank')?.focus();
  }
}
