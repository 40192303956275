<form class="data_box_package" [formGroup]="form" [ngStyle]="{'border': last ? 'none':''}">
  <div class="create_contact_header">
    <h2>Kontaktperson {{index > 0 ? ('('+(index+1)+')') : ''}}</h2>
    <img *ngIf="index != 0" class="icon_contact_remove" src="../../../../../assets/delete.svg" (click)="removeContactEmitter.emit(index)">
  </div>
  <div class="grid_horizontal_3">
    <div class="input_wrapper">
      <label class="lbl_regular_12">Anrede</label>
      <div class="dropdown_wrapper" (click)="showDropdownOne = !showDropdownOne" appDropdown [show]="showDropdownOne" (changedValueEmitter)="showDropdownOne = $event">
        <input #inputPolite placeholder="Auswählen..." [class.input_invalid]="submitted && form.controls['contact_polite'].invalid" data-readonly>
        <img class="dropdown_icon" [ngClass]="showDropdownOne ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
        <div class="dropdown_value_container" *ngIf="showDropdownOne">
          <label (click)="form.controls['contact_polite'].setValue(true); inputPolite.value = 'Sie'" class="dropdown_element lbl_dropdown_small">Sie</label>
          <label (click)="form.controls['contact_polite'].setValue(false); inputPolite.value = 'Du'" class="dropdown_element lbl_dropdown_small">Du</label>
        </div>
      </div>
    </div>
    <div class="input_wrapper">
      <label class="lbl_regular_12">Geschlecht *</label>
      <div class="dropdown_wrapper" (click)="showDropdownTwo = !showDropdownTwo" appDropdown [show]="showDropdownTwo" (changedValueEmitter)="showDropdownTwo = $event">
        <input #inputSalutation  placeholder="Auswählen..." [class.input_invalid]="submitted && form.controls['contact_salutation'].invalid" data-readonly>
        <img class="dropdown_icon" [ngClass]="showDropdownTwo ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
        <div class="dropdown_value_container" *ngIf="showDropdownTwo">
          <label (click)="form.controls['contact_salutation'].setValue(false); inputSalutation.value = 'Frau'" class="dropdown_element lbl_dropdown_small">Frau</label>
          <label (click)="form.controls['contact_salutation'].setValue(true); inputSalutation.value = 'Herr'" class="dropdown_element lbl_dropdown_small">Herr</label>
        </div>
      </div>
    </div>
    <div class="input_wrapper">
      <label class="lbl_regular_12">Titel</label>
      <div class="dropdown_wrapper" (click)="showDropdownThree = !showDropdownThree" appDropdown [show]="showDropdownThree" (changedValueEmitter)="showDropdownThree = $event">
        <input #inputTitle placeholder="Auswählen..." [class.input_invalid]="submitted && form.controls['contact_title'].invalid" data-readonly>
        <img class="dropdown_icon" [ngClass]="showDropdownThree ? 'dropdown_icon_open' : ''" src="../../../../assets/arrowdropdown.svg">
        <div class="dropdown_value_container" *ngIf="showDropdownThree">
          <label *ngFor="let title of titles" (click)="form.controls['contact_title'].setValue(title); inputTitle.value = title.name" class="dropdown_element lbl_dropdown_small">{{title.name}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="grid_horizontal_4">
    <div class="input_wrapper">
      <label for="contact_first_name">Vorname *</label>
      <input type="text" placeholder="Vorname" formControlName="contact_first_name" [class.input_invalid]="submitted && form.controls['contact_first_name'].invalid">
    </div>
    <div class="input_wrapper">
      <label for="contact_last_name">Nachname *</label>
      <input type="text" placeholder="Nachname" formControlName="contact_last_name" [class.input_invalid]="submitted && form.controls['contact_last_name'].invalid">
    </div>
  </div>
  <div class="grid_horizontal_4">
    <div class="input_wrapper">
      <label for="contact_phone">Telefon *</label>
      <input type="text" placeholder="Telefon" formControlName="contact_phone" [class.input_invalid]="submitted && form.controls['contact_phone'].invalid">
    </div>
    <div class="input_wrapper">
      <label for="contact_email">E-Mail *</label>
      <input type="text" placeholder="E-Mail" formControlName="contact_email" [class.input_invalid]="submitted && form.controls['contact_email'].errors">
    </div>
  </div>
  <div class="grid_horizontal_4">
    <div class="input_wrapper">
      <label for="contact_first_name">Position</label>
      <input type="text" placeholder="Position" formControlName="position" [class.input_invalid]="submitted && form.controls['position'].invalid">
    </div>
  </div>
  <div class="grid_horizontal_4">
    <div class="checkbox_wrapper" (click)="checkBoxClick($event)">
      <input type="checkbox" class="checkbox" [checked]="form.controls['bill_receiver'].value">
      <label>Rechnungsempfänger</label>
    </div>
  </div>
</form>

<app-dialog-project-allocation *ngIf="showProjectAllocation"
  [projects]="customer.projects"
  (closeEmitter)="showProjectAllocation = false"
  (applyFilterEmitter)="applyProjectContactAllocation($event)"
></app-dialog-project-allocation>
