import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Title } from 'src/app/buisness-object/customer/Title';
import { InputFiller } from 'src/app/helper/InputFiller';
import { Project } from 'src/app/buisness-object/project/Project';
import { getColorClass } from 'src/app/helper/HelperFunctions';
import { Customer } from 'src/app/buisness-object/customer/Customer';

@Component({
  selector: 'app-customer-create-contact',
  templateUrl: './customer-create-contact.component.html',
  styleUrls: ['./customer-create-contact.component.scss']
})
export class CustomerCreateContactComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() submitted!: boolean;
  @Input() last!: boolean;
  @Input() isEdit!: boolean;
  @Input() index!: number;
  @Input() titles!: Title[];
  @Input() customer: Customer;
  @Output() removeContactEmitter = new EventEmitter<number>();
  @Output() changeEmitter = new EventEmitter<undefined>();
  @Output() seclectionBillReceiverEmitter = new EventEmitter<undefined>();
  public showDropdownOne = false;
  public showDropdownTwo= false;
  public showDropdownThree = false;
  public showProjectAllocation = false;
  public showDropdownFour = false;
  @ViewChild('inputPolite') inputPolite!: ElementRef;
  @ViewChild('inputSalutation') inputSalutation!: ElementRef;
  @ViewChild('inputTitle') inputTitle!: ElementRef;
  @ViewChild('inputReceiver') inputReceiver!: ElementRef;
  public projects: Project[] = [];
  public formSubscription!: Subscription;
  public getColor = getColorClass;
  public changeDetected = false;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.formSubscription) this.formSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    if(this.isEdit && this.form){
      InputFiller.setPolite(this.inputPolite,this.form.controls['contact_polite'].value);
      InputFiller.setSalutation(this.inputSalutation,this.form.controls['contact_salutation'].value);
      InputFiller.setTitle(this.inputTitle,this.form.controls['contact_title'].value);
      InputFiller.setReceiver(this.inputReceiver,this.form.controls['bill_receiver'].value);
    } else {
      InputFiller.setPolite(this.inputPolite,this.form.controls['contact_polite'].value);
      InputFiller.setTitle(this.inputTitle,this.form.controls['contact_title'].value);
      InputFiller.setReceiver(this.inputReceiver,this.form.controls['bill_receiver'].value);
    }
    setTimeout(() => {
      this.formSubscription = this.form.valueChanges.subscribe(change => {
        this.changeEmitter.emit();
        this.formSubscription.unsubscribe();
      })
    }, 200);
  }

  applyProjectContactAllocation(projects: Project[]) {

  }

  checkBoxClick(event){
    if(event.target.type === "checkbox"){
       event.preventDefault();
       event.stopImmediatePropagation();
    }
    this.seclectionBillReceiverEmitter.emit()
  }
}
