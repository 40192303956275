import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';

@Component({
  selector: 'app-dialog-filter-user',
  templateUrl: './dialog-filter-user.component.html',
  styleUrls: ['./dialog-filter-user.component.scss']
})
export class DialogFilterUserComponent implements OnInit {
  @Input() filter: any;
  @Input() users: User[] = [];
  @Output() closeEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  public selectedUsers: User[] = [];
  public usersSeller: User[] = [];

  constructor() { }

  ngOnInit(): void {
    for(let user of this.users){
      if(user.isSeller) this.usersSeller.push(user);
    }
    for(let u of this.filter.users){
      this.selectedUsers.push(CloneObject.deepCopy(u));
    }
  }

  isChecked(user: User): boolean {
    let index = this.selectedUsers.findIndex(u => u.userId == user.userId);
    if(index > -1) return true;
    else return false;
  }
  selectionUser(user: User) {
    let index = this.selectedUsers.findIndex(u => u.userId == user.userId);
    if(index > -1) this.selectedUsers.splice(index, 1);
    else this.selectedUsers.push(CloneObject.deepCopy(user));
  }

  close() {
    this.closeEmitter.emit();
  }

  applyFilter() {
    this.filter.users = this.selectedUsers;
    this.applyFilterEmitter.emit(this.filter);
  }
}
