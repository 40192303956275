<div class="data_box">
  <div class="data_box_package data_box_package_last">
    <h2>{{form.controls['section_name'].value}}</h2>
    <div *ngFor="let formRow of form.controls['section_rows'].value" style="margin-bottom: 5px; width: 100%" >
      <form [formGroup]="formRow">
        <div class="input_wrapper">
          <label class="lbl_regular_12">{{formRow.controls['title'].value}}</label>
          <input [type]="getInputType(formRow)" [placeholder]="formRow.controls['title'].value" formControlName="data_value"
                [ngClass]="submitted && this.formRow.controls['data_value'].invalid ? 'input_error' : ''">
        </div>
      </form>
      <!-- <input type="date" type="month" [value]="getDateForForm(1685059200000)"> -->
    </div>
  </div>
</div>
