import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';

@Component({
  selector: 'app-dialog-filter-status',
  templateUrl: './dialog-filter-status.component.html',
  styleUrls: ['./dialog-filter-status.component.scss']
})
export class DialogFilterStatusComponent implements OnInit {
  @Input() filter: any;
  @Output() closeEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  public selectedStatus: number[] = [];

  constructor() { }

  ngOnInit(): void {
    for(let u of this.filter.status){
      this.selectedStatus.push(CloneObject.deepCopy(u));
    }
  }

  selectionStatus(status: number) {
    let index = this.selectedStatus.findIndex(r => r == status);
    if(index > -1) this.selectedStatus.splice(index, 1);
    else this.selectedStatus.push(CloneObject.deepCopy(status));
  }

  close() {
    this.closeEmitter.emit();
  }

  applyFilter() {
    this.filter.status = this.selectedStatus;
    this.applyFilterEmitter.emit(this.filter);
  }
}
