import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageFactory } from 'src/app/buisness-object/thread/factory/MessageFactory';
import { ThreadFactory } from 'src/app/buisness-object/thread/factory/ThreadFactory';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private http: HttpClient,
    private lService: LoginService
  ) { }

  createMessage(message: Message): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.post(environment.api + '/back/thread/' + message.threadId + '/message',
    {
      message: {
        threadId: message.threadId,
        message: message.message,
        timestamp: message.timestamp
      }
    }, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }

  updateMessage(threadId: number, message: Message): Observable<Message>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.put(environment.api + '/back/thread/' + threadId + '/message/' + message.messageId,
    {
      message: {
        message_id: message.messageId,
        threadId: threadId,
        message: message.message,
        timestamp: message.timestamp
      }
    }, { headers });
    return observable.pipe(
      map((rawMessage: any) => {
        const message = MessageFactory.jsonFactoryOne(rawMessage);
        return message;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }

  deleteMessage(threadId: number, message: Message): Observable<Message>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/thread/' + threadId + '/message/' + message.messageId, { headers });
    return observable.pipe(
      map((rawMessage: any) => {
        const message = MessageFactory.jsonFactoryOne(rawMessage);
        return message;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }
}
