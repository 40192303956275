import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { FormValidators, validateLink } from 'src/app/helper/FormValidators';
import { ContactPerson } from '../../customer/ContactPerson';
import { Customer } from '../Customer';
import { Title } from '../Title';
import { Section, SectionRow } from '../../project/Section';
import { formatDate } from '@angular/common';

export class CustomerForm {
  static createCustomerForm(formbuilder: FormBuilder, customer?: Customer): FormGroup {
    let form: FormGroup;
    let contactForms: FormGroup[] = [];
    if(customer){
      for(let contact of customer.contact_persons){
        contactForms.push(this.addContactForm(formbuilder, contact));
      }
    } else {
      contactForms.push(this.addContactForm(formbuilder));
    }
    let sectionForms: FormGroup[] = [];
    if(customer){
      for(let section of customer.sections){
        sectionForms.push(this.addSectionForm(formbuilder, section));
      }
    }
    form = formbuilder.group({
      company_name: [customer?.company_name ? customer?.company_name : null, Validators.required],
      seller: [customer?.seller ? customer?.seller : null, Validators.required],
      website_url: [customer ? customer.website_url : null, validateLink],
      atu: [customer?.billing_address.atu_number ? customer?.billing_address.atu_number : null],
      typ: [customer?.customer_typ ? customer?.customer_typ : null,Validators.required],
      billing_company_name: [customer?.billing_address.company_name ? customer?.billing_address.company_name : null, [Validators.required, Validators.min(1)]],
      street: [customer ? customer.billing_address.address.street : null, Validators.required],
      postal_code: [customer ? customer.billing_address.address.postalCode : null, [Validators.required, Validators.pattern('\\-?\\d*\\.?\\d{1,2}'), Validators.min(1)]],
      city: [customer ? customer.billing_address.address.city : null, Validators.required],
      contacts: [contactForms],
      last_appointment: [null],
      has_rating: [customer ? customer.has_rating :false],
      data_privacy_available: [customer ? customer.has_dataprivacy : false],
      thread: [null],
      sections: [sectionForms],
    })
    return form;
  }

  static addContactForm(formbuilder: FormBuilder, contact?: ContactPerson): FormGroup {
    return formbuilder.group({
        contact_id: [contact ? contact.contact_id : 0, [Validators.required]],
        contact_email: [contact ? contact.email : null, [Validators.required,Validators.email]],
        contact_first_name: [contact ? contact.firstName : null, Validators.required],
        contact_last_name: [contact ? contact.lastName : null, Validators.required],
        contact_phone: [contact ? contact.phone : null,[Validators.required]],
        contact_title: [contact ? contact.title : new Title(3,'Keine Angabe'), Validators.required],
        contact_salutation: [contact ? contact.isMale : null, Validators.required],
        contact_polite: [contact ? contact.isPolite : true],
        position: [contact ? contact.position : null],
        projects: [contact ? contact.projects : []],
        bill_receiver: [contact ? contact.bill_receiver : false],
    })
  }

  static createSections(formbuilder: FormBuilder, sections: Section[], customer?: Customer): FormGroup[] {
    let sectionForms = [];
    for(let section of sections){
      let included = false;
      if(customer){
        for(let sectionCustomer of customer.sections){
          if(section.section_id == sectionCustomer.section_id){
            sectionForms.push(this.addSectionForm(formbuilder, sectionCustomer));
            included = true;
          }
        }
      }
      if(!included) sectionForms.push(this.addSectionForm(formbuilder,section));
    }
    return sectionForms;
  }

  static addSectionForm(formbuilder: FormBuilder, section: Section): FormGroup {
    return formbuilder.group({
      section_id: [section?.section_id],
      section_name: [section?.section_name ? section.section_name : '---'],
      section_rows: [section.section_rows ? this.createSectionRowForms(formbuilder,section.section_rows): []],
    })
  }
  static createSectionRowForms(formbuilder: FormBuilder, rows: SectionRow[]): FormGroup[] {
    let forms = [];
    for(let row of rows){
      forms.push(formbuilder.group({
        section_row_id: [row?.section_row_id],
        type: [row?.type ? row.type : null],
        data_value: [row?.data_value ? row.data_value : null],
        title: [row?.title ? row.title : null],
      }))
    }
    return forms;
  }

  static getConvertedDateForInputFull(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-MM-dd', 'de')
    }
    return null;
  }

  static getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }
}
