import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Thread } from 'src/app/buisness-object/thread/Thread';

@Component({
  selector: 'app-ticket-list-element',
  templateUrl: './ticket-list-element.component.html',
  styleUrls: ['./ticket-list-element.component.scss']
})
export class TicketListElementComponent implements OnInit {
  public resolved = false;
  @Input() thread!: Thread;
  @Input() last!: boolean
  @Output() openThreadEmitter = new EventEmitter<Thread>();
  @Output() dissolveEmitter = new EventEmitter<Thread>();
  @Output() restoreEmitter = new EventEmitter<Thread>();

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  getLastMessageName(thread: Thread): string {
    thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    if(thread.messages.length > 0){
      return thread.messages[thread.messages.length - 1].user?.firstName + ' ' + thread.messages[thread.messages.length - 1].user?.lastName;
    }
    return '';
  }

  getLastMessageDate(thread: Thread): number {
    thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    if(thread.messages.length > 0){
      return thread.messages[thread.messages.length - 1].timestamp;
    }
    return 0;
  }

  openThread() {
    this.openThreadEmitter.emit(this.thread);
  }

  resolve() {
    this.resolved = true;
    setTimeout(() => {
      this.dissolveEmitter.emit(this.thread);
    }, 400);
  }

  restore() {
    this.resolved = true;
    setTimeout(() => {
      this.restoreEmitter.emit(this.thread);
    }, 400);
  }

  transform(text:string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  }
}
