import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { DialogService } from 'src/app/service/dialog/dialog.service';

class TagElement {
  constructor(
    public tag_id: number,
    public tag_name: string
  ){}
}

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss']
})
export class RichTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: User[];
  @Input() editorId: number = 1;
  @Input() activateBold: boolean = true;
  @Input() activateItalic: boolean = true;
  @Input() activateUnderline: boolean = true;
  @Input() activateStrikeThrougth: boolean = true;
  @Input() openAnimate: boolean = true;
  @Input() control: any;
  @Input() maxChar: number = 4500;
  @ViewChild("richtText") richtText;

  public users = [];
  public richTextFormatButtons = [];
  public recordCommands: string[] = [];
  public isSelection: boolean = false;
  public inputTimeout: any;
  public inputRichTextEditorTimeout;

  public editorIdentifier;
  public usersFiltered: TagElement[] = [];
  public usersAvailable: TagElement[] = [];
  public usersTagged: TagElement[] = [];

  public searchStr = '';
  public userArrowIndex = 0;
  public placeArrowIndex = 0;
  public message;

  //styles
  tagBackgroundColor = 'transparent';
  tagFontColor = '#2A2A2A;';
  tagHoverBackgroundColor = '#64a3da;';
  tagHoverFontColor = '#ffffff';

  private dialogQuerySubsription: Subscription;
  private lastCursorPosition;
  private pasteEvent;


  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.editorIdentifier = 'text-input-'+this.editorId;
    for(let d of this.data) {
      this.users.push(new TagElement(
        d.userId,
        d.firstName+d.lastName.slice(0,1).toUpperCase()+d.lastName.slice(1,d.lastName.length)
      ))
    }
    this.usersFiltered = this.users;
    for(let u of this.users){
      this.usersAvailable.push(CloneObject.deepCopy(u));
    }
    // this.dialogQuerySubsription = this.dialogService.closeDialogQuery$.subscribe((value) => {
    //   if(value){
    //     if(value.typ == 'query_paste_style' && value.submit_value == true){
    //       this.handlePaste(this.pasteEvent, true);
    //     } else {
    //       this.handlePaste(this.pasteEvent, false);
    //     }
    //   }
    // });
  }

  ngOnDestroy(): void {
    document.getElementById('editorIdentifier')?.removeAllListeners();
    document.getElementById('editorIdentifier')?.remove();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  ngAfterViewInit(): void {
    let unsorted = document.querySelectorAll(".richtext-format-button");
    unsorted.forEach(element => {
      if(this.isCurrentEditor(element)) this.richTextFormatButtons.push(element);
    });

    //Mac character limit
    let element = document.getElementById("text-input-"+this.editorId);
    if(element){
      element.addEventListener("keydown", (event: any) => {
        this.onInput(event);
        if(this.inputRichTextEditorTimeout) clearTimeout(this.inputRichTextEditorTimeout);
        this.inputRichTextEditorTimeout = setTimeout(() => {
          this.getMessage();
        }, 300);
      });

      element.addEventListener("mouseup", (event: any) => {
        this.isSelection = this.isTextSelected(event);
      });
    }

    //Rich Text Editor
    this.intializer();
    this.addListeners();
    this.addEditorAddFunktionListeners();
  }



  intializer() {
    this.highlighter(this.richTextFormatButtons, true);
  }

  addListeners() {
    if(this.richTextFormatButtons){
      this.richTextFormatButtons.forEach(button => {
        if(this.isCurrentEditor(button)) {
          button.addEventListener("click", () => {
            this.modifyText(button.id,false,null);
            if(this.isSelection){
              setTimeout(() => {
                window.getSelection().removeAllRanges();
                this.modifyText(button.id,false,null);
              }, 100);
            }
            const input = document.getElementById("text-input-"+this.editorId);
            if(input) this.control?.setValue(input.innerHTML);
          });
        }
      });
    }
    if(this.openAnimate){
      const editor = document.getElementById(this.editorIdentifier);
      if(editor != null){
        editor.addEventListener('focus', () => {
          editor.setAttribute('style','min-height: 100px');
        });
        editor.addEventListener('blur', (event) => {
          let el = event.target as HTMLElement;
          if(el != null){
            let child = el.nextSibling as HTMLElement;
            if(child != null && child.id && child.id == 'tag_dropdown'){
              return;
            }
          }
          editor.setAttribute('style','min-height: 5px');
        });
      }
    } else {
      const editor = document.getElementById(this.editorIdentifier);
      if(editor != null){
        editor.setAttribute('style','height: 100%');
      }
    }
  }

  highlighter(classNameArray, needsRemoval) {
    if(classNameArray){
      classNameArray.forEach(button => {
        button.addEventListener("click", () => {
          if(needsRemoval){
            let active = false;
            if(this.isCurrentEditor(button) && button.classList.contains("richtext-format-button-active")){
              active = true;
            }
            this.highlighterRemover(classNameArray);
            if(!active && !this.isSelection){
              if(this.isCurrentEditor(button)) button.classList.add("richtext-format-button-active");
            }
          } else {
            if(this.isCurrentEditor(button)) button.classList.toggle("richtext-format-button-active");
          }
        });
      });
    }
  }

  isCurrentEditor(button): boolean {
    return button.id.split("-")[1] == this.editorId
  }

  highlighterRemover(className) {
    className.forEach(button => {
      if(this.isCurrentEditor(button)) {
        button.classList.remove("richtext-format-button-active");
      }
    });
  }

  modifyText(command, defaultUi, value) {
    document.execCommand(command.split("-")[0], defaultUi, value);
  }

  onInput(event) {
    if(event.target.innerText.length > this.maxChar && event.keyCode != 8){
      event.preventDefault();
      event.stopPropagation()
    } else {
      if(this.inputTimeout) clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
        this.addTextToControls();
      }, 200);
    }
  }

  addTextToControls() {
    let element = document.getElementById("text-input-"+this.editorId);
    if(element){
      this.control?.setValue(element.innerHTML);
    }
  }



  // onPaste(event) {
  //   // const { data, target } = event;
  //   // this.lastCursorPosition = this.getCaretPosition(target);
  //   this.handleClipboardPaste(event)
  //   // event.stopPropagation();
  //   // event.preventDefault();
  //   // this.pasteEvent = event;
  //   // this.dialogService.openQuery(
  //   //   {
  //   //     title: 'Text einfügen',
  //   //     message: 'Ohne Style einfügen?',
  //   //     btn_submit_txt: 'Style entfernen',
  //   //     submit_value: true,
  //   //     typ: 'query_paste_style',
  //   //     showClose: true,
  //   //   }
  //   // );
  // }

//   handlePaste(e, removeStyles) {
//     navigator.clipboard.readText().then(text => {
//         if(removeStyles) {
//             this.insertTextAtCursor(text);
//         } else {
//             const clipboardData = e.clipboardData
//             const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
//             this.insertHtmlAtCursor(pastedData);
//         }
//     }).catch(err => {
//         console.error('Failed to read clipboard contents: ', err);
//     });
//   }

//   insertHtmlAtCursor(html) {
//     const selection = window.getSelection();
//     if (!selection.rangeCount) return;

//     const range = selection.getRangeAt(0);

//     // Create a temporary element to hold the HTML content
//     const tempElement = document.createElement('div');
//     tempElement.innerHTML = html;

//     // Get the HTML content from the temporary element
//     const fragment = document.createDocumentFragment();
//     while (tempElement.firstChild) {
//         fragment.appendChild(tempElement.firstChild);
//     }

//     // Insert the HTML content at the cursor position
//     range.deleteContents();
//     range.insertNode(fragment);

//     // Move the cursor to the end of the inserted content
//     range.setStartAfter(fragment);
//     range.collapse(true);

//     // Update the selection with the modified range
//     selection.removeAllRanges();
//     selection.addRange(range);
//  }

//  handleClipboardPaste(event) {
//     event.preventDefault();

//     navigator.clipboard.readText().then(text => {
//         // Handle the pasted text (e.g., insert at cursor position)
//         this.insertHtmlAtCursor(text);
//     }).catch(error => {
//         console.error('Failed to read clipboard contents:', error);
//         // Handle the error gracefully (e.g., show a notification to the user)
//     });
//   }

 insertTextAtCursor(text) {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    selection.deleteFromDocument();

    const range = selection.getRangeAt(0);
    range.deleteContents();
    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    range.setStartAfter(textNode);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  // async selectionPastNoStyle() {
  //   //event.stopPropagation();
  //   //event.preventDefault();

  //   var clipboardData, pastedData;
  //   pastedData = await navigator.clipboard.readText();
  //   this.insertTextAtCursor(pastedData);

  //   // if(pastedData.length <= this.maxChar){
  //   //   pastedData = pastedData.substring(0,this.maxChar);
  //   // }
  //   // while(pastedData.includes("\r\n\r\n")){
  //   //   pastedData = pastedData.replace("\r\n\r\n","\r\n");
  //   // }
  //   // let inputText = document.getElementById("text-input-"+this.editorId);
  //   // if(inputText){
  //   //   inputText.innerText = pastedData;
  //   //   this.control?.setValue(pastedData);
  //   // }
  // }

  async onPaste() {
    event.stopPropagation();
    event.preventDefault();

    var pastedData;
    if(navigator.clipboard && window.isSecureContext){
      pastedData = await navigator.clipboard?.readText();
    }

    let allText = '';
    let inputText = document.getElementById("text-input-"+this.editorId);
    if(inputText){
      allText = inputText.innerText;
    }

    allText += pastedData;
    if(allText.length > this.maxChar){
      let allowedLength = this.maxChar - (allText.length - pastedData.length);
      pastedData = pastedData.substring(0, allowedLength);
    }
    this.insertTextAtCursor(pastedData);
    // while(pastedData.includes("\r\n\r\n")){
    //   pastedData = pastedData.replace("\r\n\r\n","\r\n");
    // }
    if(inputText){
      this.control?.setValue(inputText.innerText);
    }
  }


  isTextSelected(element: any): boolean {
    return window.getSelection().type == "Range";
  }

  //Addfunktion
  addEditorAddFunktionListeners() {
    document.getElementById(this.editorIdentifier).addEventListener('input', (event: any) => {
      const { data, target } = event;
      if (data === '@') { // Check if the input is the "@" symbol
        this.searchStr = '';
        const sel = window.getSelection();
        const node = sel.anchorNode;
        const cursorPosition = this.getCaretPosition(target); // Get the cursor position in the contenteditable div
        let text = target.innerText.slice(0,cursorPosition)
        let words = text.split('\n');
        text = target.innerText.slice(0,cursorPosition+words.length)
        //TODO: new line @ make able to show
        if(event.srcElement.innerText[cursorPosition-2+(words.length-1)] == ' '  ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\u00A0' ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\n' ||
           (words[0] == '@') ||
          words[words.length-1] == '' ||
          (event.srcElement.innerText[cursorPosition-2+(words.length-1)] == undefined && (event.srcElement.innerText[event.srcElement.innerText.length-1] == '@' && (event.srcElement.innerText[event.srcElement.innerText.length-2] == ' ' || event.srcElement.innerText[event.srcElement.innerText.length-2] == '\u00A0' || event.srcElement.innerText[event.srcElement.innerText.length-2] == '\n')))){
          this.placeArrowIndex = 0;
          const dropdown = this.createDropdown(cursorPosition, node, sel.anchorOffset);
          let data = this.getPixelOfCuretPostion(target);
          dropdown.style.left = data.left + 'px';
          dropdown.style.top = data.top + 24 + 'px';
          target.parentElement.insertBefore(dropdown, target.nextSibling); // Insert the dropdown after the cursor position
        }
      }
    });
    // Listen for keyup in the contenteditable div to check for backspace key press at the position of a user tag
    document.getElementById(this.editorIdentifier).addEventListener('keypress', (event: any) => {
      const { key, target } = event;
      let el = document.getElementById('tag_dropdown');
      if(el){
        if(key === 'Enter'){
          if(this.placeArrowIndex > -1){
            this.selectionTagWithEnter(this.placeArrowIndex,target)
            this.placeArrowIndex = 0;
            el.remove();
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
          } else {
            this.placeArrowIndex = 0;
            el.remove();
          }
        } else if(((event.keyCode >= 97 && event.keyCode <= 122) ||
           event.keyCode == 228 ||
           event.keyCode == 246 ||
           event.keyCode == 251) &&
           event.keyCode != 64
          ){
          this.searchStr = this.searchStr + event.key;
          this.searchAction(this.searchStr);
        } else if(event.keyCode == 32 || event.keyCode == 64){ //whitespace || @
          el.remove();
        } else {
          event.preventDefault();
          event.stopImmediatePropagation();
          return false;
        }
      }
    });

    document.getElementById(this.editorIdentifier).addEventListener('keydown', (event: any) => {
      const { key, target } = event;
      const dropdown = document.getElementById('tag_dropdown');
      if(key === 'Backspace' && dropdown){
        const cursorPosition = this.getCaretPosition(target);
        this.checkRemovedTextForTags(target);
        this.searchStr = this.searchStr.slice(0,-1);
        let text = target.innerText.slice(0,cursorPosition)
        let words = text.split('\n');
        if(event.srcElement.innerText[cursorPosition-2+(words.length-1)] == ' '  ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\u00A0' ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\n' ||
           (words[0] == '@') ||
          words[words.length-1] == '' ||
          (event.srcElement.innerText[cursorPosition-2+(words.length-1)] == undefined && event.srcElement.innerText[event.srcElement.innerText.length-1] == '@')){
          if(dropdown)  dropdown.remove();
        } else if(dropdown == null && event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '@' && event.srcElement.innerText[cursorPosition-3] == ' ' ){
          const sel = window.getSelection();
          const node = sel.anchorNode;
          let dropdown = this.createDropdown(cursorPosition, node, sel.anchorOffset);
          let data = this.getPixelOfCuretPostion(target);
          dropdown.style.left = (data.left - 4) + 'px';
          dropdown.style.top = data.top + 22 + 'px';
          target.parentElement.insertBefore(dropdown, target.nextSibling);
        } else if(dropdown) this.searchAction(this.searchStr)
      } else if(dropdown){
        if(key === 'ArrowUp'){
          if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.usersFiltered.length - 1);
          else this.placeArrowIndex--;
          this.setTagHoverStyle(this.placeArrowIndex)
          event.preventDefault();
          event.stopImmediatePropagation();
        } else if(key === 'ArrowDown'){
          if(this.placeArrowIndex == (this.usersFiltered.length - 1)) this.placeArrowIndex = 0;
          else this.placeArrowIndex++;
          this.setTagHoverStyle(this.placeArrowIndex);
          event.preventDefault();
          event.stopImmediatePropagation();
        } else if(key === 'ArrowLeft' || key === 'ArrowRight' || key === "Tab"){
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      }
    });
  }

    // Function to get the cursor position in the contenteditable div
    getCaretPosition(element) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    }

    // Function to create the dropdown
    createDropdown(cursorPosition, node, anchorPos) {
      const dropdown = document.createElement('div');
      this.setTagDropdownStyle(dropdown);

      // Create a list of user options
      const userList = document.createElement('ul');
      userList.id = 'tag_list'
      this.setTagListStyle(userList);
      this.usersAvailable.forEach((user) => {
        const userOption = document.createElement('li');
        userOption.id = 'tag_list_element_'+user.tag_id;
        userOption.textContent = user.tag_name;
        this.setTagListElementStyle(userOption);
        userOption.addEventListener('click', () => {
          this.createTag(user,cursorPosition,node,anchorPos,dropdown)
        });
        userList.appendChild(userOption);
      });
      dropdown.appendChild(userList);
      this.setTagHoverStyle(0,userList);
      return dropdown;
    }

    createTag(user,cursorPosition,node,anchorPos,dropdown) {
      this.usersTagged.push(user);
      dropdown.remove(); // Remove the dropdown after a user is selected
      const contenteditable = document.getElementById(this.editorIdentifier);
      const userTag = this.createUserTag(user);
      let range = document.createRange();
      range.selectNodeContents(contenteditable);
      if(cursorPosition-1 == node.length) {
        range.setStart(node, node.length)
        range.insertNode(userTag);
        range.collapse(true);
      } else {
        let sel = window.getSelection();
        let nodeAfter = document.createTextNode('');
        node.nodeValue = node.nodeValue.slice(0, anchorPos-1) + node.nodeValue.slice(anchorPos, node.nodeValue.length);
        range.setStart(node, anchorPos - 1)
        range.insertNode(nodeAfter);
        range.insertNode(userTag);
        range.collapse(true);
        userTag.previousSibling.nodeValue = userTag.previousSibling.nodeValue.slice(0,userTag.previousSibling.nodeValue.length - this.searchStr.length)
        range = document.createRange();
        range.setStart(userTag, 0)
        range.collapse(true);
        userTag.nextSibling.nodeValue = "\u00A0";
        range.setStartAfter(userTag.nextSibling);
        range.setEndAfter(userTag.nextSibling);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      this.setCaretPosition(contenteditable, userTag)
      this.usersFiltered = this.usersAvailable;
    }

    setTagDropdownStyle(el: HTMLDivElement) {
      el.id = 'tag_dropdown'
      el.setAttribute('style', this.getTagDropdownStyle());
    }
    setTagListStyle(el: HTMLUListElement) {
      el.contentEditable = 'false';
      el.setAttribute('style', this.getTagListStyle());
    }
    setTagListElementStyle(el: HTMLLIElement) {
      el.contentEditable = 'false';
      el.setAttribute('style', this.getTagStyleDefault());
      el.addEventListener('mouseover',(e) => {
        el.setAttribute('style', this.getTagStyleActive());
      })
      el.addEventListener('mouseleave', (e) => {
        el.setAttribute('style', this.getTagStyleDefault());
      })
    }

    getTagDropdownStyle(): string {
      return 'position: absolute; ' +
        'top: 0; ' +
        'left: 0; ' +
        'background-color: #ffffff; ' +
        'border: 1px solid #ccc; ' +
        'border-radius: 4px; ' +
        'z-index: 1; ' +
        'width: 164px; ' +
        'padding: 0; ' +
        'margin: 0;';
    }

    getTagListStyle(): string {
      return 'list-style: none; ' +
        'padding: 0; ' +
        'margin: 0; ' +
        'max-height: 124px;' +
        'overflow-x: scroll;';
    }

    getTagStyleDefault(): string {
      return 'background-color: ' + this.tagBackgroundColor + '; ' +
        'padding: 5px; ' +
        'margin: 0; ' +
        'font-size: 12px; ' +
        'font-weight: bold; ' +
        'color: ' + this.tagFontColor + '; ' +
        'cursor: pointer; ' +
        'transition: 0.2;';
    }

    getTagStyleActive(): string {
      return 'background-color: ' + this.tagHoverBackgroundColor + '; ' +
        'padding: 5px; ' +
        'margin: 0; ' +
        'font-size: 12px; ' +
        'font-weight: bold; ' +
        'color: ' + this.tagHoverFontColor + '; ' +
        'cursor: pointer; ' +
        'transition: 0.2;';
    }

    selectionTagWithEnter(index: number, target) {
      let tag_list = document.getElementById('tag_list') as any;
      let el = tag_list.children[index];
      let i = this.usersFiltered.findIndex(u => el.id.includes('tag_list_element_'+u.tag_id))
      if(i > -1){
        const sel = window.getSelection();
        const node = sel.anchorNode;
        const cursorPosition = this.getCaretPosition(target); // Get the cursor position in the contenteditable div
        const dropdown = document.getElementById('tag_dropdown')
        this.createTag(this.usersFiltered[i],cursorPosition,node,sel.anchorOffset,dropdown)
      }
    }

    checkRemovedTextForTags(target) {
      const text = target.innerHTML;
      let arry = text.split('"');
      for(let i = 0; i < this.usersTagged.length; i++){
        let isAdded = false;
        for(let word of arry){
          if(word.includes('user_tag_'+this.usersTagged[i].tag_id)){
            isAdded = true;
          }
        }
        if(!isAdded){
          this.usersTagged.splice(i,1);
          i--;
        }
      }
    }

    getPixelOfCuretPostion(target) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const rect = range.getClientRects()[0];
      const divRect = target.getBoundingClientRect();
      return { left: rect.left - divRect.left, top: rect.top - divRect.top}
    }

    searchAction(value: string) {
      if(value && value.length > 0){
        this.usersFiltered = [];
        this.usersFiltered = this.usersAvailable.filter((user) => {
          return user.tag_name.toLowerCase().startsWith(value.toLowerCase());
        })
      } else {
        this.usersFiltered = this.usersAvailable;
      }
      let list = document.getElementById('tag_list');
      if(list){
        //remove
        for(let j = 0; j < list.childNodes.length; j++){
          let remove = true;
          for(let i = 0; i < this.usersFiltered.length; i++){
            if(list.childNodes[j]){
              let node = list.childNodes[j] as any
              if(node.id == 'tag_list_element_'+this.usersFiltered[i].tag_id){
                remove = false;
                break;
              }
            }
          }
          if(remove){
            list.childNodes[j].remove();
            j--;
          }
        }
        //add
        for(let i = 0; i < this.usersFiltered.length; i++){
          let exists = false;
          for(let j = 0; j < list.childNodes.length; j++){
            if(list.childNodes[j]){
              let node = list.childNodes[j] as any
              if(node.id == 'tag_list_element_'+this.usersFiltered[i].tag_id){
                exists = true;
              }
            }
          }
          if(!exists) {
            const userOption = document.createElement('li');
            userOption.id = 'tag_list_element_'+this.usersFiltered[i].tag_id;
            userOption.textContent = this.usersFiltered[i].tag_name;
            this.setTagListElementStyle(userOption);
            list.appendChild(userOption);
          }
        }
      }
      this.placeArrowIndex = 0;
      this.setTagHoverStyle(this.placeArrowIndex,list);
    }

    setTagHoverStyle(index: number, tagList?) {
      this.placeArrowIndex = index;
      if(tagList){
        for(let i = 0; i < tagList.children.length; i++){
          if(i == index) {
            tagList.children[i].setAttribute('style', this.getTagStyleActive());
            tagList.children[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          } else {
            tagList.children[i].setAttribute('style', this.getTagStyleDefault());
          }
        }
      } else {
        let tag_list = document.getElementById('tag_list') as any;
        for(let i = 0; i < tag_list.children.length; i++){
          if(i == index) {
            tag_list.children[i].setAttribute('style', this.getTagStyleActive());
            tag_list.children[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          } else {
            tag_list.children[i].setAttribute('style', this.getTagStyleDefault());
          }
        }
      }
    }

  createUserTag(user) {
    const userTag = document.createElement('label');
    let count = 1;
    for(let u of this.usersTagged){
      if(u.tag_id == user.tag_id) count++;
    }
    userTag.id = 'user_tag_'+user.tag_id+'_'+count;
    this.setUserTagStyle(userTag);
    userTag.textContent = user.tag_name;
    return userTag;
  }

  setUserTagStyle(el: HTMLLabelElement) {
    el.contentEditable = 'false';
    el.className = 'user-tag';
    el.setAttribute('style', this.getInTextTagStyle());
  }

  getInTextTagStyle(): string {
    return 'display: inline-block;' +
      'background-color: #64a3da; ' +
      'color: #ffffff; ' +
      'padding: 0px 4px; ' +
      'margin: 0 2px; ' +
      'border-radius: 2px; ' +
      'font-size: 12px !important; ' +
      'font-weight: 300; ' +
      'font-family: var(--font);';
  }

    // Function to set the cursor position in the contenteditable div
    setCaretPosition(element, userTag) {
      //TODO: cursor sprung
      for(let i = 0; i < element.childNodes.length; i++){
        if(element.childNodes[i].id === userTag.id){
          let node = element.childNodes[i+1];
          node.nodeValue = "\u00A0";
          let range = document.createRange();
          range.setStart(node, node.nodeValue.length);
          range.setEnd(node, node.nodeValue.length);
          range.collapse(true);
          element.focus();
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          break;
        }
      }
    }

    getMessage() {
      const content = document.getElementById('text-input-'+this.editorId);
      const html = content.innerHTML;
      let rowArry = [];
      let stop = false;
      let row = '';
      for(let i = 0; i <= html.length; i++){
        if((html[i] + html[i+1] + html[i+2] + html[i+3] + html[i+4]) === '<div>'){
          if(row.length > 0) rowArry.push(row);
          row =  '';
          i += 5;
        } else if((html[i] + html[i+1] + html[i+2] + html[i+3] + html[i+4] + html[i+5]) === '</div>'){
          stop = true;
          if(row.length > 0) rowArry.push(row);
          row =  '';
          if(i+5 < html.length) i+=5;
        } else if(i==html.length) rowArry.push(row);
        if(!stop) row += html[i];
        stop = false;
      }
      let convertedText = '';
      for(let i = 0; i < rowArry.length; i++){
        let start = false;
        let middle = false;
        let end = false;
        let wordArray = rowArry[i].split(' ');
        let previousTxt = '';
        for(let word of wordArray){
          let strong = '<strong ';
          if(!start && word.includes('<label')){
            start = true;
            previousTxt = word.slice(0,word.indexOf('<'))
          }
          if(middle && word.includes('</label>&nbsp;')) end = true;
          if(start){
            if(word.includes('id="user_tag_')){
              let split = word.split('_')
              strong += 'id="'+split[2]+'">'
              let index = this.usersTagged.findIndex(u => u.tag_id == Number(split[2]));
              if(index > -1) strong += '@'+this.usersTagged[index].tag_name+'</strong>';
              if(previousTxt.includes('var(--font);"')){
                previousTxt = ' '
              }
              convertedText += previousTxt + strong;
              start = false;
              middle = true;
            }
          }
          if(end){
            const index = word.indexOf('&')
            word = ' '+word.slice(index+6,word.length);
            end = false;
            middle = false;
          }
          if(!start && !middle && !end) convertedText += word + ' ';
        }
        if(i != rowArry.length-1){
          let end = convertedText.slice(-5, convertedText.length-1);
          if(end != '<br>') convertedText+='<br>'
        }
      }
      this.message = convertedText;
      this.control.setValue(this.message)
    }
}
