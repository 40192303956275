<div id="notification_container" *ngIf="show">
  <div id="notification_box">
    <div class="column_1" [ngClass]="isSuccess ? 'success' : 'fail'">
      <img class="icon" [src]="isSuccess ? '../../../../assets/positivewhite.svg' : '../../../../assets/negativewhite.svg'">
    </div>
    <div class="column_2">
      <div class="container_txt">
        <label class="lbl_medium_28 title">{{title}}</label>
        <label class="lbl_regular_14 message">{{message}}</label>
      </div>
    </div>
  </div>
</div>
