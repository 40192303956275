
<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Ansprechpersonen</h2>
      </div>
      <div class="dialog_window_body">
        <div class="flex_slider" *ngFor="let contact of contacts">
          <div class="lbl_regular_12" style="margin-bottom: 0;">{{contact.firstName}} {{contact.lastName}}</div>
          <div class="slider_container" (click)="selectionTag(contact)">
            <div class="slider_still" [ngClass]="tagIsSelected(contact) ? '' : 'slider_still_on'"></div>
            <div class="slider_move" [ngClass]="tagIsSelected(contact) ? 'slider_on' : 'slider_off'"></div>
          </div>
        </div>
      </div>
    </div>
    <button class="dialog_window_btn_submit" (click)="onSubmit()">Auswählen</button>
  </div>
</div>
