import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() submitted!: boolean;
  @Input() last!: boolean;
  @Input() index!: number;
  @Output() changeEmitter = new EventEmitter<undefined>();
  public formSubscription: Subscription[] = [];

  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i < this.form.controls['section_rows'].value.length; i++){
      this.formSubscription.push(this.form.controls['section_rows'].value[i].valueChanges.subscribe(change => {
        this.changeEmitter.emit();
        for(let formSub of this.formSubscription){
          if(formSub) formSub.unsubscribe();
        }
      }))
    }
  }

  ngOnDestroy(): void {
    for(let formSub of this.formSubscription){
      if(formSub) formSub.unsubscribe();
    }
  }

  getDateForForm(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp),'yyyy-mm', 'de');
    }
    return null;
  }

  getInputType(form: FormGroup): string {
    if(form.controls['type'].value == 'string') return "text";
    else if(form.controls['type'].value == 'link') return "text";
    else if(form.controls['type'].value == 'password') return "text";
    else if(form.controls['type'].value == 'date') return "date";
    else return "text";
    //form.controls['type'].value == 'int' ? 'number' : (formRow.controls['type'].value == 'string' ? 'text' : 'text')
  }
}
