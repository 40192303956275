import { FormGroup } from "@angular/forms";

export class ErrorHelper {

  public static showFormError(form: FormGroup) {
    if(form){
      Object.keys(form.controls).forEach(control => {
        if(form.controls[control].invalid){
          console.log(control);
          console.log(form.controls[control]);
        }
      })
    }
  }
}
