import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SectionRow } from 'src/app/buisness-object/project/Section';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-section-overview',
  templateUrl: './section-overview.component.html',
  styleUrls: ['./section-overview.component.scss']
})
export class SectionOverviewComponent implements OnInit {
  @Input() sectionRow: SectionRow;
  @Output() selectionSectionEmitter = new EventEmitter<SectionRow>();
  public showPassword = false;

  constructor(
    private clip: Clipboard,
    private dService: DialogService
  ) { }

  ngOnInit(): void {

  }

  copyText(str: string){
    this.clip.copy(str);
    this.dService.showNotification({
      title: 'Kopiert!',
      message: str,
      success: true
    });
  }

  openLink(url: string) {
    window.open(url, '_blank')!.focus();
    // let pattern = '^(https?:\\/\\/)?' + // protocol
    //     '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
    //     '[a-zA-Z]{2,13})' + // extension
    //     '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
    //     '|localhost)' + // OR localhost
    //     '(\\:\\d{1,5})?' + // port
    //     '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
    //     '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
    //     '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    // let regex = new RegExp(pattern);
    // if(url && regex.test(url)){
    //   window.open(url, '_blank')!.focus();
    // } else {
    //   this.dService.showNotification({
    //     title: 'Fehler',
    //     message: 'Link kann nicht geöffnet werden.',
    //     success: false
    //   });
    // }
  }
}
