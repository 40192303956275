import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, AfterViewInit {
  public hardness = 8;
  public amountOfRows = 0;
  public rowsLenght = 0;
  public dividerRows = 66;
  public dividerRowsElement = 72;
  public rows: Row[] = [];
  public failed = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    let width = window.innerWidth;
    let height = window.innerHeight;
    this.amountOfRows = Math.ceil(height / this.dividerRows);
    this.rowsLenght = Math.ceil(width / this.dividerRowsElement);
    this.readyUp();
    this.calculateValues();
  }

  ngAfterViewInit(): void {
    let html = document.getElementById('html');
    html.style.backgroundColor = '#000000';
    let body = document.getElementById('body');
    body.style.opacity = '1';
  }

  readyUp() {
    let j = 0;
    for(let i = 1; i < this.amountOfRows+2; i++){
      let boxes = [];
      for(j; j < (this.rowsLenght * i); j++){
        boxes.push(
          new Box(
            j,
            this.getRandomBomb(),
            false,
          )
        )
      }
      this.rows.push(
        new Row(
          i,
          boxes
        )
      )
    }
    for(let i = 0; i < this.rows.length; i++){
      for(j = 0; j < this.rows[i].boxes.length; j++){
        if(this.isBorder(i,j)){
          this.rows[i].boxes[j].is_border = true;
          this.rows[i].boxes[j].number = -1;
        }
      }
    }
  }

  isBorder(i: number, j: number): boolean {
    return (i==0) || (j==0) || (j==this.rows[i].boxes.length - 1) || (i==this.rows.length-1);
  }

  getRandomBomb(): number {
    let ran = Math.floor(Math.random() * (this.hardness - 1 + 1)) + 1;
    return ran == this.hardness ? this.hardness : 0;
  }

  async calculateValues() {
    for(let i = 0; i < this.rows.length; i++){
      for(let j = 0; j < this.rows[i].boxes.length; j++){
        if(this.rows[i].boxes[j].number != this.hardness && !this.rows[i].boxes[j].is_border){
          if(i%2==0){
            if(this.rows[i]?.boxes[j-1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i]?.boxes[j+1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i-1]?.boxes[j-1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i-1]?.boxes[j]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i+1]?.boxes[j-1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i+1]?.boxes[j]?.number == this.hardness) this.rows[i].boxes[j].number++;
          } else {
            if(this.rows[i]?.boxes[j-1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i]?.boxes[j+1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i-1]?.boxes[j]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i-1]?.boxes[j+1]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i+1]?.boxes[j]?.number == this.hardness) this.rows[i].boxes[j].number++;
            if(this.rows[i+1]?.boxes[j+1]?.number == this.hardness) this.rows[i].boxes[j].number++;
          }
        }
      }
    }
  }

  openBox(box: Box, row_index: number, box_index: number) {
    let element = document.getElementById('hex_'+box.box_id);
    if(element){
      if(box.number == this.hardness){
        element.className = 'hex_bomb';
        for(let row of this.rows){
          for(let box of row.boxes){
            if(box.number == this.hardness){
              let el = document.getElementById('hex_'+box.box_id);
              if(el) el.className = 'hex_bomb';
              this.failed = true;
              setTimeout(() => {
                let page = document.getElementById('page');
                page.style.opacity = '0';
                setTimeout(() => {
                  let html = document.getElementById('html');
                  html.style.backgroundColor = '#000000';
                  let body = document.getElementById('body');
                  body.style.opacity = '0';
                  setTimeout(() => {
                    this.router.navigate(['customers'])
                    setTimeout(() => {
                      let html = document.getElementById('html');
                      // html.style.backgroundColor = 'var(--color-interface-4)';
                      let body = document.getElementById('body');
                      body.style.opacity = '1';
                    }, 300);
                  }, 500);
                }, 200);
              }, 3000);
            }
          }
        }
      } else if(box.number == 0) {
        element.className = 'hex_clicked';
        let found = 0;
        let arize = 1;
        while (found <= 6) {
          if(this.rows[row_index]?.boxes[box_index-arize]?.number == Number(0) && !this.rows[row_index]?.boxes[box_index-arize]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index].boxes[box_index-arize].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          if(this.rows[row_index]?.boxes[box_index+arize]?.number == Number(0) && !this.rows[row_index]?.boxes[box_index+arize]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index].boxes[box_index+arize].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          if(this.rows[row_index-arize]?.boxes[box_index-arize]?.number == Number(0) && !this.rows[row_index-arize]?.boxes[box_index-arize]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index-arize].boxes[box_index-arize].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          if(this.rows[row_index-arize]?.boxes[box_index]?.number == Number(0) && !this.rows[row_index-arize]?.boxes[box_index]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index-arize].boxes[box_index].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          if(this.rows[row_index+arize]?.boxes[box_index+arize]?.number == Number(0) && !this.rows[row_index+arize]?.boxes[box_index+arize]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index+arize].boxes[box_index+arize].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          if(this.rows[row_index+arize]?.boxes[box_index]?.number == Number(0) && !this.rows[row_index+arize]?.boxes[box_index]?.is_border){
            let element = document.getElementById('hex_'+this.rows[row_index+arize].boxes[box_index].box_id);
            if(element) element.className = 'hex_clicked';
          } else found++;
          arize++;
        }
      } else {
        element.className = 'hex_clicked';
      }

    }
  }
}

class Row {
  constructor(
    public row_id: number,
    public boxes: Box[]
  ){}
}

class Box {
  constructor(
    public box_id: number,
    public number: number,
    public is_border: boolean
  ){}
}
