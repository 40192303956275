import { Address } from "./Address";
import { ContactPerson } from "./ContactPerson";
import { AddressFactory } from "./factory/AddressFactory";
import { ContactPersonFactory } from "./factory/ContactPersonFactory";

export class BillngAddress {
  constructor(
    public company_name: string,
    public address: Address,
    public atu_number: string,
    public billing_mail?: string,
    public contact_person_id?: number,
    public contact_person?: ContactPerson,
  ){}

  getJSON(): any {
    return {
      "company_name": this.company_name,
      "atu_number": this.atu_number,
      "street": this.address.street,
      "postal_code" : this.address.postalCode,
      "city": this.address.city,
      "contact_person_id" : this.contact_person?.contact_id
    }
  }
}

export class BillngAddressFactory {
  static jsonFactoryOne(raw: any): BillngAddress {
    return new BillngAddress(
      raw.company_name,
      AddressFactory.jsonFactoryOne(raw.address),
      raw.atu_number,
      raw.billing_mail,
      raw.contact_person_id,
      raw.contact_person ? ContactPersonFactory.jsonFactoryOne(raw.contact_person): undefined,
    );
  }
}
