<div class="ticket_list_element"
  [ngStyle]="{'opacity': resolved ? '0' : '1'}"
  [ngStyle]="{'background-color': thread.notifyOpen ? 'black' : ''}"
  [ngStyle]="{'border': last ? 'none' : ''}">
    <div class="column_1">
      <label class="lbl_customer_data_box_data">Nr. {{thread.threadId}}</label>
      <div class="lbl_customer_data_box_data">{{thread.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</div>
      <div class="lbl_regular_14">{{thread.customer?.company_name}}</div>
    </div>
    <div class="column_2">
      <img class="user_img_container"
        [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
        [src]="thread.user.getAvatar()">
      <div class="text_container">
        <div class="lbl_regular_14">{{thread.user.firstName}} {{thread.user.lastName}}</div>
        <div class="lbl_customer_data_box_data lbl_message" [innerHTML]="transform(thread.getThreadTitle(200))"></div>
      </div>
    </div>
    <div class="column_3">
      <div class="lbl_regular_14">{{thread.messages.length > 0 ? thread.messages.length - 1 : '0'}} Kommentare</div>
      <div class="lbl_customer_data_box_data">{{getLastMessageDate(thread) | date: 'dd. MMM YYYY'}}, {{getLastMessageName(thread)}}</div>
    </div>
    <div *ngIf="!thread.resolved" class="btn_resolve_container" (click)="resolve()">
      <img class="icon_resolve" src="../../../../assets/clear.svg">
      <button class="btn_label">Auflösen</button>
    </div>
    <div *ngIf="thread.resolved" class="btn_resolve_container" (click)="restore()">
      <img class="icon_resolve" src="../../../../assets/direction.svg">
      <button class="btn_label">Wiederherstellen</button>
    </div>
    <button class="lbl_color_box" [ngClass]="thread.resolved ? 'lbl_color_box_green' : 'lbl_color_box_red'">{{thread.resolved ? 'Erledigt' : 'offen'}}</button>
    <img class="icon_arrow_right" src="../../../../assets/arrowdropdown.svg" (click)="openThread()">
</div>
