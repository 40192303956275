import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';

class TagElement {
  constructor(
    public tag_id: number,
    public tag_name: string
  ){}
}

@Component({
  selector: 'app-custom-mark-input',
  templateUrl: './custom-mark-input.component.html',
  styleUrls: ['./custom-mark-input.component.scss']
})
export class CustomMarkInputComponent implements OnInit, OnDestroy {
  @Input() data: User[];
  @Input() btn_name: string;
  @Input() create: boolean = false;
  @Output() selectionCreateEmitter = new EventEmitter<string>();
  @Output() selectionCreateAndCloseEmitter = new EventEmitter<string>();
  public users = []
  public usersFiltered: TagElement[] = [];
  public usersAvailable: TagElement[] = [];
  public usersTagged: TagElement[] = [];
  public searchStr = '';
  public userArrowIndex = 0;
  public placeArrowIndex = 0;
  public message;

  //styles
  tagBackgroundColor = 'transparent';
  tagFontColor = 'var(--font-color)';
  tagHoverBackgroundColor = 'var(--hover-color)';
  tagHoverFontColor = 'var(--font-color)';

  constructor() { }

  ngOnInit(): void {
    for(let d of this.data) {
      this.users.push(new TagElement(
        d.userId,
        d.firstName+d.lastName.slice(0,1).toUpperCase()+d.lastName.slice(1,d.lastName.length)
      ))
    }
    this.usersFiltered = this.users;
    for(let u of this.users){
      this.usersAvailable.push(CloneObject.deepCopy(u));
    }
  }

  ngOnDestroy(): void {
    document.getElementById('contenteditable').removeAllListeners();
    document.getElementById('contenteditable').remove();
  }

  ngAfterViewInit(): void {
    // Listen for key input in the contenteditable div
    document.getElementById('contenteditable').addEventListener('input', (event: any) => {
      const { data, target } = event;
      if (data === '@') { // Check if the input is the "@" symbol
        this.searchStr = '';
        const sel = window.getSelection();
        const node = sel.anchorNode;
        const cursorPosition = this.getCaretPosition(target); // Get the cursor position in the contenteditable div
        let text = target.innerText.slice(0,cursorPosition)
        let words = text.split('\n');
        text = target.innerText.slice(0,cursorPosition+words.length)
        //TODO: new line @ make able to show
        if(event.srcElement.innerText[cursorPosition-2+(words.length-1)] == ' '  ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\u00A0' ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\n' ||
           (words[0] == '@') ||
          words[words.length-1] == '' ||
          (event.srcElement.innerText[cursorPosition-2+(words.length-1)] == undefined && (event.srcElement.innerText[event.srcElement.innerText.length-1] == '@' && (event.srcElement.innerText[event.srcElement.innerText.length-2] == ' ' || event.srcElement.innerText[event.srcElement.innerText.length-2] == '\u00A0' || event.srcElement.innerText[event.srcElement.innerText.length-2] == '\n')))){
          this.placeArrowIndex = 0;
          const dropdown = this.createDropdown(cursorPosition, node, sel.anchorOffset);
          let data = this.getPixelOfCuretPostion(target);
          dropdown.style.left = data.left + 'px';
          dropdown.style.top = data.top + 24 + 'px';
          target.parentElement.insertBefore(dropdown, target.nextSibling); // Insert the dropdown after the cursor position
        }
      }
    });
    // Listen for keyup in the contenteditable div to check for backspace key press at the position of a user tag
    document.getElementById('contenteditable').addEventListener('keypress', (event: any) => {
      const { key, target } = event;
      let el = document.getElementById('tag_dropdown');
      if(el){
        if(key === 'Enter'){
          if(this.placeArrowIndex > -1){
            this.selectionTagWithEnter(this.placeArrowIndex,target)
            this.placeArrowIndex = 0;
            el.remove();
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
          } else {
            this.placeArrowIndex = 0;
            el.remove();
          }
        } else if(((event.keyCode >= 97 && event.keyCode <= 122) ||
           event.keyCode == 228 ||
           event.keyCode == 246 ||
           event.keyCode == 251) &&
           event.keyCode != 64
          ){
          this.searchStr = this.searchStr + event.key;
          this.searchAction(this.searchStr);
        } else if(event.keyCode == 32 || event.keyCode == 64){ //whitespace || @
          el.remove();
        } else {
          event.preventDefault();
          event.stopImmediatePropagation();
          return false;
        }
      }
    });

    document.getElementById('contenteditable').addEventListener('keydown', (event: any) => {
      const { key, target } = event;
      const dropdown = document.getElementById('tag_dropdown');
      if(key === 'Backspace' && dropdown){
        const cursorPosition = this.getCaretPosition(target);
        this.checkRemovedTextForTags(target);
        this.searchStr = this.searchStr.slice(0,-1);
        let text = target.innerText.slice(0,cursorPosition)
        let words = text.split('\n');
        if(event.srcElement.innerText[cursorPosition-2+(words.length-1)] == ' '  ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\u00A0' ||
           event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '\n' ||
           (words[0] == '@') ||
          words[words.length-1] == '' ||
          (event.srcElement.innerText[cursorPosition-2+(words.length-1)] == undefined && event.srcElement.innerText[event.srcElement.innerText.length-1] == '@')){
          if(dropdown)  dropdown.remove();
        } else if(dropdown == null && event.srcElement.innerText[cursorPosition-2+(words.length-1)] == '@' && event.srcElement.innerText[cursorPosition-3] == ' ' ){
          const sel = window.getSelection();
          const node = sel.anchorNode;
          let dropdown = this.createDropdown(cursorPosition, node, sel.anchorOffset);
          let data = this.getPixelOfCuretPostion(target);
          dropdown.style.left = (data.left - 4) + 'px';
          dropdown.style.top = data.top + 22 + 'px';
          target.parentElement.insertBefore(dropdown, target.nextSibling);
        } else if(dropdown) this.searchAction(this.searchStr)
      } else if(dropdown){
        if(key === 'ArrowUp'){
          if(this.placeArrowIndex <= 0) this.placeArrowIndex = (this.usersFiltered.length - 1);
          else this.placeArrowIndex--;
          this.setTagHoverStyle(this.placeArrowIndex)
          event.preventDefault();
          event.stopImmediatePropagation();
        } else if(key === 'ArrowDown'){
          if(this.placeArrowIndex == (this.usersFiltered.length - 1)) this.placeArrowIndex = 0;
          else this.placeArrowIndex++;
          this.setTagHoverStyle(this.placeArrowIndex);
          event.preventDefault();
          event.stopImmediatePropagation();
        } else if(key === 'ArrowLeft' || key === 'ArrowRight' || key === "Tab"){
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      }
    });
  }

  setTagHoverStyle(index: number, tagList?) {
    this.placeArrowIndex = index;
    if(tagList){
      for(let i = 0; i < tagList.children.length; i++){
        if(i == index) {
          tagList.children[i].style.backgroundColor = this.tagHoverBackgroundColor;
          tagList.children[i].style.color = this.tagHoverFontColor;
        } else {
          tagList.children[i].style.backgroundColor =  this.tagBackgroundColor;
          tagList.children[i].style.color = this.tagFontColor;
        }
      }
    } else {
      let tag_list = document.getElementById('tag_list') as any;
      for(let i = 0; i < tag_list.children.length; i++){
        if(i == index) {
          tag_list.children[i].style.backgroundColor = this.tagHoverBackgroundColor;
          tag_list.children[i].style.color = this.tagHoverFontColor;
        } else {
          tag_list.children[i].style.backgroundColor = this.tagBackgroundColor;
          tag_list.children[i].style.color = this.tagFontColor;
        }
      }
    }
  }

  selectionTagWithEnter(index: number, target) {
    let tag_list = document.getElementById('tag_list') as any;
    let el = tag_list.children[index];
    let i = this.usersFiltered.findIndex(u => el.id.includes('tag_list_element_'+u.tag_id))
    if(i > -1){
      const sel = window.getSelection();
      const node = sel.anchorNode;
      const cursorPosition = this.getCaretPosition(target); // Get the cursor position in the contenteditable div
      const dropdown = document.getElementById('tag_dropdown')
      this.createTag(this.usersFiltered[i],cursorPosition,node,sel.anchorOffset,dropdown)
    }
  }

  checkRemovedTextForTags(target) {
    const text = target.innerHTML;
    let arry = text.split('"');
    for(let i = 0; i < this.usersTagged.length; i++){
      let isAdded = false;
      for(let word of arry){
        if(word.includes('user_tag_'+this.usersTagged[i].tag_id)){
          isAdded = true;
        }
      }
      if(!isAdded){
        this.usersTagged.splice(i,1);
        i--;
      }
    }
  }

  getPixelOfCuretPostion(target) {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const rect = range.getClientRects()[0];
    const divRect = target.getBoundingClientRect();
    return { left: rect.left - divRect.left, top: rect.top - divRect.top}
  }

  searchAction(value: string) {
    if(value && value.length > 0){
      this.usersFiltered = [];
      this.usersFiltered = this.usersAvailable.filter((user) => {
        return user.tag_name.toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.usersFiltered = this.usersAvailable;
    }
    let list = document.getElementById('tag_list');
    if(list){
      //remove
      for(let j = 0; j < list.childNodes.length; j++){
        let remove = true;
        for(let i = 0; i < this.usersFiltered.length; i++){
          if(list.childNodes[j]){
            let node = list.childNodes[j] as any
            if(node.id == 'tag_list_element_'+this.usersFiltered[i].tag_id){
              remove = false;
              break;
            }
          }
        }
        if(remove){
          list.childNodes[j].remove();
          j--;
        }
      }
      //add
      for(let i = 0; i < this.usersFiltered.length; i++){
        let exists = false;
        for(let j = 0; j < list.childNodes.length; j++){
          if(list.childNodes[j]){
            let node = list.childNodes[j] as any
            if(node.id == 'tag_list_element_'+this.usersFiltered[i].tag_id){
              exists = true;
            }
          }
        }
        if(!exists) {
          const userOption = document.createElement('li');
          userOption.id = 'tag_list_element_'+this.usersFiltered[i].tag_id;
          userOption.textContent = this.usersFiltered[i].tag_name;
          this.setTagListElementStyle(userOption);
          list.appendChild(userOption);
        }
      }
    }
    this.placeArrowIndex = 0;
    this.setTagHoverStyle(this.placeArrowIndex,list);
  }

  // Function to get the cursor position in the contenteditable div
  getCaretPosition(element) {
    const range = window.getSelection().getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }

    // Function to create the dropdown
  createDropdown(cursorPosition, node, anchorPos) {
    const dropdown = document.createElement('div');
    this.setTagDropdownStyle(dropdown);

    // Create a list of user options
    const userList = document.createElement('ul');
    userList.id = 'tag_list'
    this.setTagListStyle(userList);
    this.usersAvailable.forEach((user) => {
      const userOption = document.createElement('li');
      userOption.id = 'tag_list_element_'+user.tag_id;
      userOption.textContent = user.tag_name;
      this.setTagListElementStyle(userOption);
      userOption.addEventListener('click', () => {
        this.createTag(user,cursorPosition,node,anchorPos,dropdown)
      });
      userList.appendChild(userOption);
    });
    dropdown.appendChild(userList);
    this.setTagHoverStyle(0,userList);
    return dropdown;
  }

  createTag(user,cursorPosition,node,anchorPos,dropdown) {
    this.usersTagged.push(user);
    dropdown.remove(); // Remove the dropdown after a user is selected
    const contenteditable = document.getElementById('contenteditable');
    const userTag = this.createUserTag(user);
    let range = document.createRange();
    range.selectNodeContents(contenteditable);
    if(cursorPosition-1 == node.length) {
      range.setStart(node, node.length)
      range.insertNode(userTag);
      range.collapse(true);
    } else {
      let sel = window.getSelection();
      let nodeAfter = document.createTextNode('');
      node.nodeValue = node.nodeValue.slice(0, anchorPos-1) + node.nodeValue.slice(anchorPos, node.nodeValue.length);
      range.setStart(node, anchorPos - 1)
      range.insertNode(nodeAfter);
      range.insertNode(userTag);
      range.collapse(true);
      userTag.previousSibling.nodeValue = userTag.previousSibling.nodeValue.slice(0,userTag.previousSibling.nodeValue.length - this.searchStr.length)
      range = document.createRange();
      range.setStart(userTag, 0)
      range.collapse(true);
      userTag.nextSibling.nodeValue = "\u00A0";
      range.setStartAfter(userTag.nextSibling);
      range.setEndAfter(userTag.nextSibling);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    this.setCaretPosition(contenteditable, userTag)
    this.usersFiltered = this.usersAvailable;
  }

  setTagDropdownStyle(el: HTMLDivElement) {
    el.id = 'tag_dropdown'
    el.style.position = 'absolute';
    el.style.top = '0px';
    el.style.left = '0px';
    el.style.backgroundColor = 'white';
    el.style.border = '1px solid #ccc';
    el.style.borderRadius = '4px';
    el.style.zIndex = '1';
    el.style.width = '160px';
    el.style.padding = '0';
    el.style.margin = '0';
    // max-height: 100px;
    // overflow: scroll;
    // overflow-x: hidden;
  }
  setTagListStyle(el: HTMLUListElement) {
    el.contentEditable = 'false';
    el.style.listStyle = 'none';
    el.style.padding = '0';
    el.style.margin = '0';
  }
  setTagListElementStyle(el: HTMLLIElement) {
    el.contentEditable = 'false';
    el.style.padding = '1px 5px';
    el.style.margin = '0';
    el.style.fontFamily = 'var(--font)';
    el.style.fontSize = '12px';
    el.style.fontWeight = '500';
    el.style.color = 'var(--font-color)';
    el.style.cursor = 'pointer';
    el.style.transition = '0.2';
    el.addEventListener('mouseover',(e) => {
      el.style.backgroundColor = this.tagHoverBackgroundColor;
      el.style.color = this.tagHoverFontColor;
    })
    el.addEventListener('mouseleave', (e) => {
        el.style.backgroundColor = this.tagBackgroundColor;
      el.style.color = this.tagFontColor;
    })
  }

  // Function to set the cursor position in the contenteditable div
  setCaretPosition(element, userTag) {

    //TODO: cursor sprung

    for(let i = 0; i < element.childNodes.length; i++){
      if(element.childNodes[i].id === userTag.id){
        let node = element.childNodes[i+1];
        node.nodeValue = "\u00A0";
        let range = document.createRange();
        range.setStart(node, node.nodeValue.length);
        range.setEnd(node, node.nodeValue.length);
        range.collapse(true);
        element.focus();
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        break;
      }
    }
  }

    // Function to create a user tag
  createUserTag(user) {
    const userTag = document.createElement('label');
    let count = 1;
    for(let u of this.usersTagged){
      if(u.tag_id == user.tag_id) count++;
    }
    userTag.id = 'user_tag_'+user.tag_id+'_'+count;
    this.setUserTagStyle(userTag);
    userTag.textContent = user.tag_name;
    return userTag;
  }
  setUserTagStyle(el: HTMLLabelElement) {
    el.contentEditable = 'false';
    el.className = 'user-tag';
    el.style.display = 'inline-block';
    el.style.backgroundColor = 'var(--color-tag-5)';
    el.style.color = '#ffffff';
    el.style.padding = '0px 4px';
    el.style.margin = '0 2px';
    el.style.borderRadius = '4px';
    el.style.fontSize = '12px';
    el.style.fontWeight = '300';
    el.style.fontFamily = 'var(--font)';
  }

  // Function to get the position of a user tag
  getTagPosition(tag) {
    const range = document.createRange();
    range.setStart(tag, 0);
    range.setEndBefore(tag);
    return range.toString().length;
  }

  getTextWidth(text: string): number {
    let c = document.createElement('canvas');
    let ctx = c.getContext('2d');
    ctx!.font = '400 16px Nunito';
    let temp = text?.split('\n');
    let width = ctx!.measureText(temp[temp.length - 1]).width;
    return width;
  }
  getLineHeight(): number {
    let content = document.getElementById('contenteditable');
    if(content){
      let linesCount = 0;
      let arry = content.innerText.split('\n');
      let minus = 0;
      for(let i = 0; i < arry.length; i++){
        if(arry[i] == ''&& arry[i + 1] == ''){
          minus++;
          i++;
        }
      }
      linesCount = arry.length - minus;
      let top = Number(window.getComputedStyle(content).lineHeight.substring(0, window.getComputedStyle(content).lineHeight.length - 2)) * linesCount;
      return top;
    }
  }

  onPaste(event: ClipboardEvent) {
    //TODO: Image paste node
    // const dT = event.clipboardData;
    // const file = dT.files[ 0 ];
    // const img = new Image();
    // const url = URL.createObjectURL(file);
    // img.src = url;
    // this.createImage(img);
    event.preventDefault();
    var text = '';
    if (event.clipboardData) {
      text = event.clipboardData.getData('text/plain');
    }
    if (document.queryCommandSupported('insertText')) {
      document.execCommand('insertText', false, text);
    } else {
      document.execCommand('paste', false, text);
    }
  }

  createImage(img) {
    const selection = window.getSelection();
    const node = selection.anchorNode;
    const anchorPos = selection.anchorOffset;
    const cursorPosition = this.getCaretPosition(document.getElementById('contenteditable')); // Get the cursor position in the contenteditable div
    const contenteditable = document.getElementById('contenteditable');
    let range = document.createRange();
    range.selectNodeContents(contenteditable);
    if(cursorPosition-1 == node.nodeValue?.length) {
      range.setStart(node, node.nodeValue?.length)
      range.insertNode(img);
      range.collapse(true);
    } else {
      const sel = window.getSelection();
      //this.createTag(this.usersFiltered[i],cursorPosition,node,sel.anchorOffset,dropdown)
      // const sel = window.getSelection();
      // const node = sel.anchorNode;
      // const cursorPosition = this.getCaretPosition(target); // Get the cursor position in the contenteditable div
      // const dropdown = document.getElementById('tag_dropdown')
      let nodeAfter = document.createTextNode('');
      node.nodeValue = node.nodeValue.slice(0, anchorPos-1) + node.nodeValue.slice(anchorPos, node.nodeValue.length);
      range.setStart(node, anchorPos - 1)
      range.insertNode(nodeAfter);
      range.insertNode(img);
      range.collapse(true);
      img.previousSibling.nodeValue = img.previousSibling.nodeValue.slice(0,img.previousSibling.nodeValue.length - this.searchStr.length)
      range = document.createRange();
      range.setStart(img, 0)
      range.collapse(true);
      img.nextSibling.nodeValue = "\u00A0";
      range.setStartAfter(img.nextSibling);
      range.setEndAfter(img.nextSibling);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    this.setCaretPosition(contenteditable, img)
  }

  validMsg(): boolean {
    return !(document.getElementById('contenteditable').innerText.length > 1);
  }

  send(close: boolean) {
    const content = document.getElementById('contenteditable');
    const html = content.innerHTML;
    let rowArry = [];
    let stop = false;
    let row = '';
    for(let i = 0; i <= html.length; i++){
      if((html[i] + html[i+1] + html[i+2] + html[i+3] + html[i+4]) === '<div>'){
        if(row.length > 0) rowArry.push(row);
        row =  '';
        i += 5;
      } else if((html[i] + html[i+1] + html[i+2] + html[i+3] + html[i+4] + html[i+5]) === '</div>'){
        stop = true;
        if(row.length > 0) rowArry.push(row);
        row =  '';
        if(i+5 < html.length) i+=5;
      } else if(i==html.length) rowArry.push(row);
      if(!stop) row += html[i];
      stop = false;
    }
    let convertedText = '';
    for(let i = 0; i < rowArry.length; i++){
      let start = false;
      let middle = false;
      let end = false;
      let wordArray = rowArry[i].split(' ');
      let previousTxt = '';
      for(let word of wordArray){
        let strong = '<strong ';
        if(!start && word.includes('<label')){
          start = true;
          previousTxt = word.slice(0,word.indexOf('<'))
        }
        if(middle && word.includes('</label>&nbsp;')) end = true;
        if(start){
          if(word.includes('id="user_tag_')){
            let split = word.split('_')
            strong += 'id="'+split[2]+'">'
            let index = this.usersTagged.findIndex(u => u.tag_id == Number(split[2]));
            if(index > -1) strong += '@'+this.usersTagged[index].tag_name+'</strong>';
            if(previousTxt.includes('var(--font);"')){
              previousTxt = ' '
            }
            convertedText += previousTxt + strong;
            start = false;
            middle = true;
          }
        }
        if(end){
          const index = word.indexOf('&')
          word = ' '+word.slice(index+6,word.length);
          end = false;
          middle = false;
        }
        if(!start && !middle && !end) convertedText += word + ' ';
      }
      if(i != rowArry.length-1){
        let end = convertedText.slice(-5, convertedText.length-1);
        if(end != '<br>') convertedText+='<br>'
      }
    }
    this.message = convertedText;
    if(close) this.selectionCreateAndCloseEmitter.emit(this.message)
    else this.selectionCreateEmitter.emit(this.message)
  }
}
