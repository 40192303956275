import { Directive, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  @Input() show!: boolean;
  @Input() excludingElementIds!: string[];
  @Output() changedValueEmitter = new EventEmitter<boolean>();
  private listener!: () => void;
  public excludingElements: any[] = [];
  public firstClick = true;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if(this.excludingElementIds){
      for(let el of this.excludingElementIds){
        this.excludingElements.push(document.getElementById(el));
      }
    }
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      this.checkExcludesInTarget(e.target).then((inTarget) => {
        if(!this.firstClick && this.show && !this.elementRef.nativeElement?.contains(e.target) && !inTarget){
          this.changedValueEmitter.emit(false);
        } else this.firstClick = false;
      })
    });
    // this.listener = this.renderer.listen('window','click',(e:Event) => {
    //   if(this.show && !this.elementRef.nativeElement.contains(e.target)) this.changedValueEmitter.emit(false);
    // });
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  async checkExcludesInTarget(target): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      setTimeout(() => {
        if(this.excludingElements){
          for(let e of this.excludingElements){
            if(e?.contains(target)){
              return resolve(true);
            }
          }
        }
        return resolve(false);
      }, 10);
    })
  }
}
