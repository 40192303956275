import { Component, Input, OnInit, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { BaseComponent } from '../../BaseComponent';
import { Project } from 'src/app/buisness-object/project/Project';

@Component({
  selector: 'app-customer-tickets',
  templateUrl: './customer-tickets.component.html',
  styleUrls: ['./customer-tickets.component.scss']
})
export class CustomerTicketsComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showTab: number;
  @Input() customersThreadsFiltered: Thread[];
  @Input() customer: Customer;
  @Input() project: Project;
  @Input() users: User[];
  @Input() currentUser: User;
  @Input() selectedThread: Thread;
  @Input() showCreateTicket: boolean;
  @Input() minimizeSidebar: boolean;
  @Output() successResolveTicketEmitter = new EventEmitter<Thread>();
  @Output() successRestoreTicketEmitter = new EventEmitter<Thread>();
  @Output() successCreateTicketEmitter = new EventEmitter<Thread>();
  @Output() successCreateMessageEmitter = new EventEmitter<Thread>();
  @Output() selectionThreadEmitter = new EventEmitter<Thread>();
  @Output() selectionCloseThreadEmitter = new EventEmitter<undefined>();
  @Output() searchTicketsEmitter = new EventEmitter<string>();
  public dialogQuerySubsription: Subscription;
  public threadsFiltered: Thread[] = [];
  public inputTimeout;
  constructor(
    private tService: ThreadService,
    private dService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sortThreads();
    this.setDialogSubscription();
  }

  ngOnChanges(changes): void {
    if(changes['showTab']){
      //this.showCreateTicket = false;
    }
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
  }

  sortThreads() {
    if(this.customersThreadsFiltered != null){
      this.customersThreadsFiltered.sort((a, b) => {
        if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
        else if(a.resolved && !b.resolved) return 1;
        else if(!a.resolved && b.resolved) return -1;
        else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
        return 0;
      });
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'resolve_thread') this.resolveThread(value.submit_value);
        if(value.typ == 'restore_thread') this.restoreThread(value.submit_value);
      }
    });
  }


  selectionResolveThread(thread: Thread) {
    this.resolveThread(thread);
    // this.dService.openQuery(
    //   {
    //     title: 'Ticket auflösen',
    //     message: 'Alles erledigt? \nBist du dir absolut sicher das du dieses Ticket auflösen möchtest? \nZu 100%?',
    //     btn_cancel_txt: 'Abbrechen',
    //     btn_submit_txt: 'Auflösen',
    //     typ: 'resolve_thread',
    //     submit_value: thread,
    //     showClose: true,
    //     is_choise: true
    //   }
    // );
  }

  resolveThread(thread: Thread) {
    thread.status = 2;
    thread.resolved = new Date().getTime();
    thread.resolved_by_user_id = this.currentUser.userId;
    this.tService.updateThread(thread).subscribe((t) => {
      if(t){
        if(this.selectedThread) {
          this.selectedThread.status = 2;
          this.selectedThread.resolved = t.resolved;
          this.selectedThread.resolved_by_user = this.currentUser;
          this.selectedThread.resolved_by_user_id = this.currentUser.userId;
          this.successResolveTicketEmitter.emit(this.selectedThread);
          this.selectedThread = null;
        } else {
          thread.status = 2;
          thread.resolved = t.resolved;
          thread.resolved_by_user = this.currentUser;
          thread.resolved_by_user_id = this.currentUser.userId;
          this.successResolveTicketEmitter.emit(thread);
        }
      }
    })
  }

  closeCreateSuccess(thread: Thread) {
    this.successCreateTicketEmitter.emit(thread);
  }


  closeCreateAndResolveSuccess(thread: Thread) {
    this.resolveThread(thread);
  }

  selectionBack() {
    this.selectionCloseThreadEmitter.emit();
  }

  selectionRestoreThread(thread: Thread) {
    this.dService.openQuery(
      {
        title: 'Ticket wiederherstellen',
        message: 'Ohje... Doch nicht alles erledigt?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Wiederherstellen',
        typ: 'restore_thread',
        submit_value: thread,
        showClose: true,
        is_choise: true
      }
    );
  }

  restoreThread(thread: Thread) {
    thread.status = 1;
    thread.resolved = null;
    thread.resolved_by_user_id = 0;
    this.tService.updateThread(thread).subscribe((t) => {
      if(t){
        if(this.selectedThread) {
          this.selectedThread.status = 1;
          this.selectedThread.resolved = null;
          this.selectedThread.resolved_by_user = null;
          this.selectedThread.resolved_by_user_id = 0;
          this.successRestoreTicketEmitter.emit(this.selectedThread);
        } else {
          thread.status = 1;
          thread.resolved = null;
          thread.resolved_by_user = null;
          thread.resolved_by_user_id = 0;
          this.successRestoreTicketEmitter.emit(thread);
        }
      }
    })
  }

  searchAction(value: string) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.searchTicketsEmitter.emit(value);
    }, 300);
  }
}
