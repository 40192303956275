<form class="data_box" [formGroup]="form">
  <div class="data_box_package">
    <div class="create_contact_header">
      <h2>Abweichende Rechnungsadresse</h2>
      <img  class="icon_contact_remove" src="../../../../../assets/delete.svg" (click)="this.form.controls['add_different_address'].setValue(false)">
    </div>
    <div class="input_wrapper">
      <label class="lbl_regular_12">Firmenname *</label>
      <input type="text" placeholder="Name des Unternehmens" formControlName="billing_company_name" [required]="submitted && this.form.controls['billing_company_name'].invalid">
    </div>
    <div class="input_wrapper">
      <label class="lbl_regular_12">ATU</label>
      <input type="text" placeholder="ATU Nummer" formControlName="atu" [required]="submitted && this.form.controls['atu'].invalid">
    </div>
    <div class="input_wrapper">
      <label class="lbl_regular_12">Straße *</label>
      <input type="text" placeholder="Straße" formControlName="street" [required]="submitted && this.form.controls['street'].invalid">
    </div>
    <div class="grid_horizontal_2">
      <div class="input_wrapper">
        <label class="lbl_regular_12">PLZ *</label>
        <input class="input_horizontal" type="text" placeholder="PLZ" formControlName="postal_code" [required]="submitted && this.form.controls['postal_code'].invalid">
      </div>
      <div class="input_wrapper">
        <label class="lbl_regular_12">Ort *</label>
        <input class="input_horizontal" type="text" placeholder="Ort" formControlName="city" [required]="submitted && this.form.controls['city'].invalid">
      </div>
    </div>
  </div>
  <div class="data_box_package data_box_package_last">
    <div class="input_wrapper">
      <label class="lbl_regular_12">Ansprechpartner *</label>
      <div #dropdown_1 class="dropdown_wrapper" (click)="showDropdow = !showDropdow" appDropdown [show]="showDropdow" (changedValueEmitter)="showDropdow = $event">
        <input #inputAddressContact class="dropdown_btn lbl_dropdown_small" placeholder="Auswählen..."  [required]="submitted && form.controls['contact_address'].invalid" data-readonly>
        <img class="dropdown_icon" [ngClass]="showDropdow ? 'dropdown_icon_rotate' : ''" src="../../../../assets/arrowdropdown.svg">
        <div *ngIf="showDropdow" class="dropdown_value_container">
          <div class="dropdown_value_container_scroll_wrapper">
            <div *ngFor="let contact of contacts" (click)="form.controls['contact_address'].setValue(contact); inputAddressContact.value = (contact.firstName + ' ' + contact.lastName)" class="dropdown_element lbl_dropdown_small">{{contact.firstName + ' ' + contact.lastName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
