import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { BaseComponent } from 'src/app/view/BaseComponent';

@Component({
  selector: 'app-customer-ticket-element',
  templateUrl: './customer-ticket-element.component.html',
  styleUrls: ['./customer-ticket-element.component.scss']
})
export class CustomerTicketElementComponent extends BaseComponent implements OnInit {
  @Input() thread: Thread;
  @Input() last: boolean;
  @Input() isProject: boolean;
  @Input() minimizeSidebar: boolean;
  @Input() currentUser: User;
  @Output() selectionDetailsEmitter = new EventEmitter<Thread>();
  @Output() resolveThreadEmitter = new EventEmitter<Thread>();
  @Output() selectionRestoreEmitter = new EventEmitter<Thread>();
  public routeParams;

  constructor(
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.isProject){
      this.routeParams = {
        'customerId': this.thread.customerId,
        'projectId': this.thread.project_id,
        'ticketId': this.thread.threadId,
      };
    } else {
      this.routeParams = {
        'customerId': this.thread.customerId,
        'ticketId': this.thread.threadId,
      };
    }
  }

  transform(text:string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  }

  resolve() {
    setTimeout(() => {
      this.resolveThreadEmitter.emit(this.thread);
    }, 400);
  }

  restore() {
    setTimeout(() => {
      this.selectionRestoreEmitter.emit(this.thread);
    }, 400);
  }

  isAuthorized(): boolean {
    if(this.thread.userId == this.currentUser.userId) return true;
    else false;
  }
}
