import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Thread } from 'src/app/buisness-object/thread/Thread';

@Component({
  selector: 'app-tickets-item',
  templateUrl: './tickets-item.component.html',
  styleUrls: ['./tickets-item.component.scss']
})
export class TicketsItemComponent implements OnInit, AfterViewInit {
  @Input() active: boolean = false;
  @Input() ticket: Thread;
  @Output() selectionItemEmitter = new EventEmitter<Thread>();
  @Output() selectionResolveEmitter = new EventEmitter<Thread>();
  @Output() selectionRestoreEmitter = new EventEmitter<Thread>();
  public selectTypes = [
    { label: 'Todo', value: 1 },
    { label: 'Feature', value: 2 },
    { label: 'Bug', value: 3 },
    { label: 'Frage', value: 4 },
    { label: 'Doku', value: 5 }
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {}

  // routerLink="/customers"
  // [queryParams]="getTicketRouteQueryParams(ticket)"

  
  ngAfterViewInit(): void {
    let aTag = document.getElementById('ticket-item_'+this.ticket.threadId);
    if(aTag){
      aTag.addEventListener('click' , (event) => {
        const element = event.target as HTMLElement;
        if(element && element.id){
          if(element.id == 'btn-ticket-resolve'){
            this.selectionResolveEmitter.emit(this.ticket);
            return;
          } else if(element.id == 'btn-ticket-restore'){
            this.selectionRestoreEmitter.emit(this.ticket);
            return;
          }
        }
        this.router.navigate(['customers'], { queryParams: this.getTicketRouteQueryParams(this.ticket) });
      })
    }
  }

  getTicketRouteQueryParams(thread: Thread): any {
    return {
      customerId: this.ticket.customerId,
      projectId: this.ticket.project_id,
      ticketId: thread.threadId
    }
  }

  getTypeLabel(value: number): string {
    const type = this.selectTypes.find(t => t.value === value);
    return type ? type.label : 'Unknown';
  }
}
