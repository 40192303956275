import { ElementRef } from "@angular/core";
import { ENUMSTATUS } from "src/app/helper/Enums";
import { AworkProject } from "../buisness-object/project/AworkProject";
import { ProjectType } from "../buisness-object/project/ProjectType";
import { User } from "../buisness-object/user/User";
import { EnumHelper } from "./EnumHelper";


export class InputFiller {
  static setTyp(element: ElementRef, value: number) {
    if(element && value) element.nativeElement.value = EnumHelper.getEnumValue(ENUMSTATUS,value-1);
  }
  static setRating(element: ElementRef, value: boolean) {
    if(element && value != null) element.nativeElement.value = value ? 'Ja' : 'Nein';
  }
  static setPolite(element: ElementRef, value: boolean) {
    if(element && value != null) element.nativeElement.value = value ? 'Sie' : 'Du';
  }
  static setSalutation(element: ElementRef, value: boolean) {
    if(element && value != null) element.nativeElement.value = value ? 'Herr' : 'Frau';
  }
  static setUserName(element: ElementRef, value: User) {
    if(element && value) element.nativeElement.value = value.firstName + ' ' + value.lastName;
  }
  static setTitle(element: ElementRef, value: any) {
    if(element && value) element.nativeElement.value = value.name;
  }
  static setReceiver(element: ElementRef, value: any) {
    if(element && value != null) element.nativeElement.value = value ? 'Ja' : 'Nein';
  }
  static setProjectType(element: ElementRef, value: ProjectType) {
    if(element && value) element.nativeElement.value = value.typ_name;
  }

  static getColorClass(color): string {
    switch (color) {
      case 'red': return 'lbl_box_red'; break;
      case 'blue': return 'lbl_box_blue'; break;
      case 'violet': return 'lbl_box_violet'; break;
      case 'yellow': return 'lbl_box_yellow'; break;
      default: break;
    }
  }
}
