<div class="customer_ticket_element" *ngIf="!isPhone">
  <div class="column_1">
    <label class="lbl_bold">Nr. {{thread.threadId}}</label>
    <label>{{thread.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</label>
  </div>
  <div class="column_2">
    <img class="user_img_container"
      [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
      [src]="thread.user!.picture ? thread.user!.picture : '../../assets/userimageplaceholder.svg'">
    <div class="column_2_inner">
      <label class="lbl_light">{{thread.user.firstName}} {{thread.user.lastName}}</label>
      <label class="lbl_customer_data_box_data lbl_message" [innerHTML]="transform(thread.getThreadTitle(minimizeSidebar ? 150 : 70))"></label>
    </div>
  </div>
  <div class="column_3">
    <label>{{thread.messages.length > 0 ? thread.messages.length - 1 : '0'}} Kommentar(e)</label>
    <label *ngIf="thread.messages.length > 1" class="lbl_customer_data_box_data">{{thread.getLastMessageDate() | date: 'dd. MMM YYYY'}}, {{thread.getLastMessageName()}}</label>
  </div>
  <div>
    <button *ngIf="!thread.resolved" class="button_icon_lbl button_action_ticket_list" (click)="resolve()"><img class="icon_resolve" src="../../../../assets/clear.svg">Auflösen</button>
    <button *ngIf="thread.resolved" class="button_icon_lbl button_action_ticket_list"  (click)="restore()"><img class="icon_resolve" src="../../../../assets/direction.svg">Wiederherstellen</button>
  </div>
  <label class="lbl_color_box" [ngClass]="thread.resolved ? 'lbl_color_box_green' : 'lbl_color_box_red'">{{thread.resolved ? 'Erledigt' : 'offen'}}</label>
  <!-- <button class="button_icon" (click)="null"><img src="../../../../assets/arrowdropdown.svg"></button> -->
  <a class="button_icon" routerLink="/customers" [queryParams]="routeParams"><img src="../../../../assets/arrowdropdown.svg"></a>
</div>

<div class="customer_ticket_element" *ngIf="isPhone">
  <div class="customer_ticket_element_phone_flex">
    <div class="column_1">
      <label class="lbl_bold">Nr. {{thread.threadId}}</label>
      <label>{{thread.createdDate | date: 'dd. MMM YYYY, HH:mm'}}</label>
      <label>{{thread.messages.length > 0 ? thread.messages.length - 1 : '0'}} Kommentar(e)</label>
    </div>
    <div class="customer_ticket_element_phone_header_right">
      <label class="lbl_color_box" [ngClass]="thread.resolved ? 'lbl_color_box_green' : 'lbl_color_box_red'">{{thread.resolved ? 'Erledigt' : 'offen'}}</label>
    </div>
  </div>
  <div class="column_2" (click)="selectionDetailsEmitter.emit(thread)">
    <img class="user_img_container"
      [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
      [src]="thread.user!.picture ? thread.user!.picture : '../../assets/userimageplaceholder.svg'">
    <div class="column_2_inner">
      <label class="lbl_light">{{thread.user.firstName}} {{thread.user.lastName}}</label>
      <label class="lbl_customer_data_box_data lbl_message" [innerHTML]="transform(thread.getThreadTitle(minimizeSidebar ? 150 : 70))"></label>
    </div>
  </div>
</div>
