import { formatDate, formatNumber, formatPercent } from "@angular/common";

export class BaseComponent {
  public isPhone = window.innerWidth <= 980;

  constructor() {

  }

  getValueString(value) {
    return value ? value : '---';
  }

  getValueNumberNoDicis(value): any {
    return value ? formatNumber(value,"de","1.0-0") : '---';
  }

  getValueNumber2Dicis(value): any {
    return value ? formatNumber(value,"de","1.2-2") : '---';
  }

  getValueCurrency(value): any {
    return value ? (formatNumber(value,"de","1.2-2")+' €') : '---';
  }

  getValueDate(value): any {
    return value ? formatDate(new Date(value),'dd.MM.yyyy, HH:mm','de') : '--.--.----';
  }
}
