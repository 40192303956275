import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project } from 'src/app/buisness-object/project/Project';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { ENUMTABCUSTOMER } from 'src/app/helper/Enums';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { Location } from '@angular/common';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Section } from 'src/app/buisness-object/project/Section';
import { getColorClass } from 'src/app/helper/HelperFunctions';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Subscription, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  @Input() selectedCustomer: Customer;
  @Input() selectedTab: number;
  @Input() selectedProject: Project;
  @Input() users: User[];
  @Input() notificationTickets: Thread[];
  public threadsFiltered: Thread[] = [];
  public selectedThread: Thread;
  public showCreateTicket = false;
  public tab = ENUMTABCUSTOMER.INFORMATION;
  public fromTab = ENUMTABCUSTOMER.INFORMATION;
  public amountOpenThreads = 0;
  public currentUser: User;
  public minimizeSidebar = false;
  public openedTicketFromInformationTab = false;
  public getColor = getColorClass;
  public routeSubscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public routeParams: any;
  @Output() closeViewEmitter = new EventEmitter<undefined>();
  @Output() selectionEditProject = new EventEmitter<Project>();
  @Output() deleteProjectEmitter = new EventEmitter<number>();

  constructor(
    private threadService: ThreadService,
    private dialogService: DialogService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.threadService.getThreads(2,this.selectedProject.project_id).subscribe((threads: Thread[]) => {
      if(threads){
        this.selectedProject.threads = threads;
        this.currentUser = this.users.find(u => u.userId == Number(localStorage.getItem('user_id')));
        this.prepareThreads();
        this.setRouteSubscriptions();
      }
    });
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  setRouteSubscriptions() {
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params['ticketId'])){
        for(let thread of this.selectedProject.threads){
          if(thread.threadId == Number(params['ticketId'])){
            this.selectionThread(thread, false);
            break;
          }
        }
      } else {
        this.selectionCloseThread();
      }
    });
  }

  getTicketRouteQueryParams(thread: Thread): any {
    return {
      customerId: this.selectedCustomer.customer_id,
      projectId: this.selectedProject.project_id,
      ticketId: thread.threadId
    }
  }

  sortThreads() {
    this.selectedProject.threads.sort((a, b) => {
      if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      else if(a.resolved && !b.resolved) return 1;
      else if(!a.resolved && b.resolved) return -1;
      else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      return 0;
    });
    this.threadsFiltered.sort((a, b) => {
      if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      else if(a.resolved && !b.resolved) return 1;
      else if(!a.resolved && b.resolved) return -1;
      else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      return 0;
    });
  }

  prepareThreads() {
    this.amountOpenThreads = 0;
    if(this.selectedProject.threads && this.selectedProject.threads.length > 0){
      for(let ticket of this.selectedProject!.threads){
        ticket.project_id = this.selectedProject.project_id;
        if(!ticket.resolved) this.amountOpenThreads++;
        for(let user of this.users){
          if(ticket.userId == user.userId) ticket.user = user;
          if(ticket.resolved_by_user_id == user.userId) ticket.resolved_by_user = user;
          for(let msg of ticket.messages){
            if(msg.userId == user.userId){
              msg.user = user;
            }
          }
        }
      }
      for(let t of this.selectedProject.threads){
        this.threadsFiltered.push(CloneObject.deepCopy(t))
      }
    }
    this.sortThreads();
  }

  openViewInfo() {
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.INFORMATION;
  }
  openViewTickets() {
    this.router.navigate(['customers'], { queryParams: { customerId: this.selectedCustomer.customer_id, projectId: this.selectedProject.project_id } });
    let el = document.getElementById('input_search_tickets') as HTMLInputElement;
    if(el) el.value = "";
    this.fromTab = ENUMTABCUSTOMER.TICKETS;
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }
  selectionOpenCreateTicket() {
    this.selectedThread = null;
    this.showCreateTicket = true;
    const content = document.getElementById('text-input-2');
    if(content) content.innerText = '';
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }

  successResolveTicket(thread: Thread) {
    let index = this.selectedProject.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedProject.threads[index].resolved = thread.resolved;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.threadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads--;
    this.selectedThread = null;
    this.sortThreads();
    this.dialogService.showNotification({
      title: this.dialogService.getTitle(),
      message: 'Ticket gelöst.',
      success: true
    });
  }
  successRestoreTicket(thread: Thread) {
    let index = this.selectedProject.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedProject.threads[index].resolved = thread.resolved;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.threadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads++;
    this.sortThreads();
    this.dialogService.showNotification({
      title: this.dialogService.getPfui(),
      message: 'Ticket wiederhergestellt.',
      success: true
    });
  }

  successCreateTicket(thread: Thread) {
    this.selectedProject.threads.push(thread);
    this.threadsFiltered.push(thread);
    this.sortThreads();
    this.amountOpenThreads++;
    this.showCreateTicket = false;
    this.dialogService.showNotification({
      title: 'Erfolgreich',
      message: 'Ticket erstellt.',
      success: true
    });
  }

  successCreateMessageEmitter(thread: Thread) {
    let index = this.selectedProject.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedProject.threads[index] = thread;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1)  this.threadsFiltered[index2] = thread;
    this.sortThreads();
  }

  selectionThread(thread: Thread, openedTicketFromInformationTab: boolean) {
    this.openedTicketFromInformationTab = openedTicketFromInformationTab;
    if(this.isNotifyTicket(thread)){
      this.removeTicketNotification(thread);
    }
    this.selectedThread = thread;
    this.showCreateTicket = false;
    this.fromTab  = this.tab;
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }

  isNotifyTicket(thread: Thread): boolean {
    for(let t of this.notificationTickets){
      if(thread.threadId == t.threadId) return true;
    }
    return false;
  }

  removeTicketNotification(thread: Thread) {
    thread.notifyOpen = false;
    this.threadService.updateThread(thread).subscribe((result) => {
      if(result){
        let index = this.selectedProject.threads.findIndex(t => t.threadId == result.threadId);
        if(index > -1) this.selectedProject.threads[index].notifyOpen = false;
        this.threadService.changeNotificationTickets(thread);
      }
    })
  }

  selectionCloseThread() {
    this.searchTicketsAction("");
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = this.fromTab;
  }

  searchTicketsAction(value: string) {
    this.threadsFiltered = this.selectedProject.threads.filter((thread) => {
      if(value && value.length > 2){
        this.threadsFiltered = [];
        return thread.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
               thread.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
               thread.threadId.toString().toLowerCase().includes(value.toLowerCase()) ||
               (thread.user.firstName + ' ' + thread.user.lastName).toLowerCase().includes(value.toLowerCase()) ||
               this.messagesIncludeSearch(value, thread.messages) ||
               thread.subject.toLowerCase().includes(value.toLowerCase());;
      } else {
        return this.threadsFiltered;
      }
    });
    this.sortThreads()
  }

  messagesIncludeSearch(value: string, messages: Message[]): boolean {
    for(let msg of messages){
      if(msg.message.toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  }

  showSectionsProject(): boolean {
    for(let section of this.selectedProject.awork_project.project_typ.sections){
      for(let row of section.section_rows){
        if(row.data_value != null) return true;
      }
    }
    return false;
  }
  showSingleSectionProject(section: Section) : boolean {
    for(let row of section.section_rows){
      if(row.data_value != null) return true;
    }
    return false;
  }

  showContactPersons(): boolean {
    let index = this.selectedProject.contact_persons.findIndex(c => c.contact_id == this.selectedProject.billing_address?.contact_person.contact_id);
    if(index > -1) return this.selectedProject.contact_persons.length - 1 > 0;
    else return this.selectedProject.contact_persons.length > 0;
  }


  hasGodAccess(): boolean {
    if(environment.api.includes("test-") && (this.currentUser?.userId == 46 || this.currentUser?.userId == 47)){
      return true;
    } else if(!environment.api.includes("test-") && (this.currentUser?.userId == 46 || this.currentUser?.userId == 47)) {
      return true;
    }
    return false;
  }

  updateTickets(ticket: Thread) {
    let index = this.selectedProject.threads.findIndex((t) => t.threadId == ticket.threadId);
    if(index > -1) this.selectedProject.threads[index] = ticket;
  }
}
