import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-customer-appointments',
  templateUrl: './dialog-customer-appointments.component.html',
  styleUrls: ['./dialog-customer-appointments.component.scss']
})
export class DialogCustomerAppointmentsComponent implements OnInit {
  @Input() dates: number[];
  @Input() showLastAppointments: boolean;
  @Output() closeEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closeEmitter.emit();
  }
}
