import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { MobileService } from 'src/app/service/mobile/mobile.service';

@Component({
  selector: 'app-customer-sidebar-element',
  templateUrl: './customer-sidebar-element.component.html',
  styleUrls: ['./customer-sidebar-element.component.scss']
})
export class CustomerSidebarElementComponent implements OnInit {
  @Input() customer!: Customer;
  @Input() paraCustomerId!: number;

  constructor(
    public mService: MobileService
  ) { }

  ngOnInit(): void {
  }


  getLastAppointmentStatus(value: number): string {
    let date = new Date(value);
    let diff = (new Date().getTime() - date.getTime()) / 1000;
    diff = diff / ((60*60*24*10*3));
    var diffMonths = Math.abs(Math.round(diff));

    if(diffMonths >= 8){
      return 'code_red';
    }
    if(diffMonths >= 6){
      return 'code_orange';
    }
    return 'code_green';
  }

  openMobileMenu() {
    this.mService.closeMobileMenu(undefined);
  }

  openUrl(url: string) {
    let pattern = '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
        '[a-zA-Z]{2,13})' + // extension
        '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '|localhost)' + // OR localhost
        '(\\:\\d{1,5})?' + // port
        '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
        '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
        '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    let regex = new RegExp(pattern);
    if(url && regex.test(url)){
      window.open('https://'+url, '_blank').focus();
    } else {
    }
  }
}
