import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-restore',
  templateUrl: './dialog-restore.component.html',
  styleUrls: ['./dialog-restore.component.scss']
})
export class DialogRestoreComponent implements OnInit {
  @Output() selectionRestoreEmitter = new EventEmitter<number>();
  @Output() closeEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }

}
