import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  private closeMobileMenuQuery = new Subject<any>();
  closeMobileMenuQuery$ = this.closeMobileMenuQuery.asObservable();


  closeMobileMenu(data: any) {
    this.closeMobileMenuQuery.next(data);
  }

  constructor() {
  }
}
