import { Title } from "../Title";

export class TitleFactory {
    static jsonFactory(rawBody: any): Title[] {
        const datas: Title[] = [];
        for(let data of rawBody){
            datas.push(this.jsonFactoryOne(data));
        }
        return datas;
    }
    
    static jsonFactoryOne(data: any): Title {
        return new Title(
            data.title_id,
            data.title_name
        )
    }
}