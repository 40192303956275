import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { MessageService } from 'src/app/service/message/message.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {
  public answers: Message[] = [];
  @Input() thread!: Thread;
  @Input() currentUser!: User;
  @Input() users!: User[];
  @Output() closeThreadEmitter = new EventEmitter<undefined>();
  @Output() dissolveEmitter = new EventEmitter<Thread>();
  @Output() restoreEmitter = new EventEmitter<Thread>();
  @Output() createMessageEmitter = new EventEmitter<Message>();
  @ViewChild('textinput', {static: false}) textInput!: ElementRef;
  public filterdUsers: User[] = [];
  public previousText = '';
  public showDropDownUser = false;
  public posTopUserBox = 0;
  public posLeftUserBox = 0;
  public lastInputPos = 0;
  public listenerFn!: () => void;
  public listenerArrowkeys!: () => void;
  public userArrowIndex = 0;
  public amountOfAddedUser = 0;
  public placeArrowIndex = -1;

  constructor(
    private elementRef: ElementRef,
    private domSanitizer: DomSanitizer,
    private mService: MessageService,
    private router: Router,
    private renderer: Renderer2
    ) { }

  ngOnInit(): void {
    this.answers = JSON.parse(JSON.stringify(this.thread.messages));
    this.answers.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    this.answers.shift();
  }

  ngOnDestroy() {
    if(this.listenerFn) this.listenerFn();
    if(this.listenerArrowkeys) this.listenerArrowkeys();
  }

  ngAfterViewInit(): void {
    window.addEventListener('keydown',(e: KeyboardEvent) => {
      if(e.key=='Enter'){
        if(this.showDropDownUser && e.target == document.getElementById('textinput')){
          e.preventDefault();
          return false;
        }
      }
      return false;
    },true);
  }

  getThreadTitle(thread: Thread): string {
    if(thread.messages?.length > 0){
      thread.messages.sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      return thread.messages[0].message;
    }
    return '---';
  }

  calcHeightTextField(value: string): number{
    let numOfLineBreaks = (value.match(/\n/g) || []).length;
    let newHeight = 20 + numOfLineBreaks * 30;
    return newHeight;
  }

  setAutoSizeFieldListener() {
    let textField = this.elementRef.nativeElement.querySelector('#input_answer');
    this.listenerFn = textField.addEventListener('keyup', () => {
      textField.style.height = this.calcHeightTextField(textField.value) + 'px';
    });
  }

  closeThread() {
    this.closeThreadEmitter.emit();
  }

  dissolve() {
    this.dissolveEmitter.emit(this.thread);
  }

  createMessage(msg: string) {
    let message = new Message(0,this.thread.threadId,Number(localStorage.getItem('user_id')),msg,Date.now());
    message.user = this.currentUser;
    this.mService.createMessage(message).subscribe((thread) => {
      if(thread){
        this.answers.push(message);
        const content = document.getElementById('contenteditable');
        if(content) content.innerText = '';
      }
    })
  }

  // convertPicture(thread: Thread | Message): SafeHtml {
  //   return this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + thread.user!.picture);
  // }

  transform(text:string):SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  }
}
