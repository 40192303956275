import { AbstractControl, FormArray, ValidatorFn, FormControl, ValidationErrors } from "@angular/forms";

export class FormValidators {
  public static minLengthArray(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
        if (!(control instanceof FormArray)) return;
        return control.length < min ? { minLength: true } : null;
    }
  }
}

export function validateLink(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if(!value) return null;
  const pattern_one = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  let regex_one = new RegExp(pattern_one);
  return !regex_one.test(value) ? { invalidlink: true } : null;
}
