import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { CustomerFactory } from 'src/app/buisness-object/customer/factory/CustomerFactory';
import { Title } from 'src/app/buisness-object/customer/Title';
import { ProductWaymark } from 'src/app/buisness-object/product/ProductWaymark';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { Section } from 'src/app/buisness-object/project/Section';
import { SectionFactory } from 'src/app/buisness-object/project/factory/SectionFactory';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public customers$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
    ) { }

  getCustomers(reload?: boolean): Observable<Customer[]> {
    if(!reload && this.customers$.getValue()){
      return of(this.customers$.getValue());
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization : 'JWT ' + localStorage.getItem('token')
      });
      let start = new Date();
      const observable = this.http.get(environment.api +  '/back/customers', { headers });
      return observable.pipe(
        map((rawCustomers: any) => {
          console.log(`Request time customers: ${(new Date().getTime()-start.getTime())/1000}`);
          const customers: Customer[] = CustomerFactory.jsonFactory(rawCustomers.customers);
          this.customers$.next(customers);
          return customers;
        }),catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            this.dService.handleError(error);
            console.log(error);
          }
          return [];
        })
      )
    }
  }

  getSections(): Observable<Section[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization : 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api +  '/back/customers/getSections', { headers });
    return observable.pipe(
      map((raw: any) => {
        const data: Section[] = SectionFactory.jsonFactory(raw.sections);
        return data;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
          console.log(error);
        }
        return [];
      })
    )
  }

  getTitles(): Observable<Title[]> {
    let title: Title[] = [
      new Title(1,'Ing.'),
      new Title(2,'Dipl. Ing.'),
      new Title(3,'Keine Angabe'),
      new Title(5,'Mag.'),
      new Title(6,'DI'),
      new Title(7,'Dr.'),
      new Title(8,'BSc.'),
      new Title(8,'MBA'),
    ];
    return of(title);
  }

  createCustomer(customer: Customer): Observable<Customer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = customer.getJSON();
    const observable = this.http.post(environment.api + '/back/customer',body, {headers});
    return observable.pipe(
      map((rawCustomer: any) => {
        const customer = CustomerFactory.jsonFactoryOne(rawCustomer.customer);
        let temp = this.customers$.getValue();
        temp.push(customer);
        this.customers$.next(temp);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde angelegt.',
          success: true
        });
        return customer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = customer.getJSON();
    const observable = this.http.put(environment.api + '/back/customer',body, {headers});
    return observable.pipe(
      map((rawCustomer: any) => {
        const customer = CustomerFactory.jsonFactoryOne(rawCustomer.customer);
        let temp = this.customers$.getValue();
        let index = temp.findIndex((c: Customer) => c.customer_id == customer.customer_id);
        if(index > -1) temp[index] = customer;
        this.customers$.next(temp);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde aktualisiert.',
          success: true
        });
        return customer;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  deleteCustomer(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/customer/' + id, {headers});
    return observable.pipe(
      map((rawCustomer: any) => {
        let temp = this.customers$.getValue();
        let index = temp.findIndex((c: Customer) => c.customer_id == id);
        if(index > -1) temp.splice(index, 1);
        this.customers$.next(temp);
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Kunde gelöscht.',
          success: true
        });
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getProducts(): Observable<ProductWaymark[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    // const observable = this.http.get(environment.api + '', { headers });
    // return observable.pipe(
    //   map((rawProducts: any) => {
    //     const products: Product[] = ProductFactory.jsonFactory(rawProducts);
    //     return products;
    //   }),
    //   catchError(error => {
    //     if(error.status == 401){
    //       this.lService.logout();
    //     } else {
    //       this.notifyService.showFailure(error.error.error_message,'Fehler');
    //     }
    //     return [];
    //   })
    // )
    return of([
      new ProductWaymark(1,'Website'),
      new ProductWaymark(2,'Webshop'),
      new ProductWaymark(3,'Marketing'),
      new ProductWaymark(4,'Software'),
      new ProductWaymark(5,'Wartung'),
    ]);
  }
}
