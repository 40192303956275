<div class="view_body">
  <button class="button_icon_lbl_dark" style="align-self: flex-start;" (click)="closeViewEmitter.emit()"><img src="../../../../../assets/navarrow-black.svg" style="rotate: 90deg;">Zurück zu {{selectedCustomer.company_name}}</button>
  <div class="customer_title_wrapper">
    <div class="customer_title_header">
      <h1>{{selectedProject.awork_project.name}}</h1>
    </div>
    <div id="action_menu">
      <img class="action_menu_button_icon" src="../../../assets/navdots.svg">
      <div id="action_menu_box">
        <div class="action" (click)="selectionEditProject.emit(selectedProject)">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.046" height="12.045" viewBox="0 0 12.046 12.045">
            <g class="action_icon" transform="translate(-806 -144.477)">
              <path class="action_menu_icon" id="Pfad_943" data-name="Pfad 943" d="M7.192,124.8.1,131.893a.334.334,0,0,0-.1.236v1.359a.365.365,0,0,0,.365.365H1.723a.334.334,0,0,0,.236-.1l7.094-7.094a.194.194,0,0,0,0-.275L7.467,124.8A.194.194,0,0,0,7.192,124.8Z" transform="translate(806 22.669)" fill="#1166B2"/>
              <path class="action_menu_icon" id="Pfad_944" data-name="Pfad 944" d="M348.757.383a1.317,1.317,0,0,0-1.862,0l-1.619,1.622a.194.194,0,0,0,0,.275l1.587,1.587a.194.194,0,0,0,.275,0l1.619-1.619a1.317,1.317,0,0,0,0-1.862Z" transform="translate(468.903 144.479)" fill="#1166B2"/>
            </g>
          </svg>
          <label>Bearbeiten</label>
        </div>
        <div class="action" *ngIf="!showCreateTicket" (click)="selectionOpenCreateTicket()">
          <svg xmlns="http://www.w3.org/2000/svg" width="6.14" height="6.14" viewBox="0 0 6.14 6.14">
            <g class="action_icon" transform="translate(5.64 0.5) rotate(90)">
              <path id="Linie_2" data-name="Linie 2" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(2.57)" fill="#3b88cc"/>
              <path id="Linie_3" data-name="Linie 3" d="M0,5.64a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V5.14A.5.5,0,0,1,0,5.64Z" transform="translate(5.14 2.57) rotate(90)" fill="#3b88cc"/>
            </g>
          </svg>
          <label>Ticket erstellen</label>
        </div>
        <div class="action" *ngIf="hasGodAccess()" (click)="deleteProjectEmitter.emit(this.selectedProject.project_id);">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.667" height="14" viewBox="0 0 11.667 14">
            <path class="action_icon" d="M53.75,2.333H51.942A2.922,2.922,0,0,0,49.084,0H47.917a2.922,2.922,0,0,0-2.858,2.333H43.25a.583.583,0,0,0,0,1.167h.583v7.583A2.92,2.92,0,0,0,46.75,14h3.5a2.92,2.92,0,0,0,2.917-2.917V3.5h.583a.583.583,0,0,0,0-1.167ZM47.917,9.917a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0v3.5Zm2.333,0a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0ZM46.267,2.333a1.754,1.754,0,0,1,1.65-1.167h1.167a1.754,1.754,0,0,1,1.65,1.167Z" transform="translate(-42.667)" fill="#3b88cc"/>
          </svg>
          <label>Projekt löschen</label>
        </div>
      </div>
    </div>
  </div>
  <div class="tab_row">
    <div class="tab_wrapper">
      <button class="btn_tab" [ngClass]="tab == 1 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="openViewInfo()">Projekt-Info</button>
      <button class="btn_tab" [ngClass]="tab == 2 ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="openViewTickets()">Tickets<!--<label class="lbl_counter" *ngIf="amountOpenThreads > 0">{{amountOpenThreads}}</label>--></button>
    </div>
    <div>
      <button *ngIf="tab == 2 && !showCreateTicket" class="btn_tab btn_tab_blue" (click)="selectionOpenCreateTicket()">Ticket erstellen</button>
      <button *ngIf="tab == 2 && showCreateTicket" class="btn_tab btn_tab_gray" (click)="selectionCloseThread();">Abbrechen</button>
    </div>
  </div>
  <div class="grid" *ngIf="tab == 1">
    <div class="column">
      <div class="data_box">
        <div class="data_box_package">
          <div class="data_box_inner_flex">
            <h2>Projektinformationen</h2>
            <label class="lbl_box" [ngClass]="selectedProject.awork_project.getStatusColorClass()">{{selectedProject.awork_project.status_name}}</label>
          </div>
          <label class="lbl_customer_data_box_data">{{selectedProject.awork_project.name}}</label>
          <label class="lbl_customer_data_box_data lbl_light">PO{{selectedProject.project_id}}</label>
          <label class="lbl_customer_data_box_data">{{selectedProject.awork_project.project_typ.typ_name}}</label>
          <!-- <label class="lbl_customer_data_box_data lbl_light">Erstellt am {{selectedProject.creation_date | date: 'dd.MM.yyyy HH:mm'}}</label> -->
          <label class="lbl_customer_data_box_data lbl_light">Vertragsbeginn am {{selectedProject.contract_start_date | date: 'dd.MM.yyyy'}}</label>
        </div>
        <div class="data_box_package">
          <h2>Projektbeschreibung</h2>
          <label class="lbl_customer_data_box_data" [innerHTML]="selectedProject.awork_project.description ? selectedProject.awork_project.description : 'Keine Angaben'"></label>
        </div>
        <div class="data_box_package">
          <h2>Zusatzanforderungen</h2>
          <label *ngIf="selectedProject.awork_project.tags.length == 0">Keine Zusatzanforderungen</label>
          <div class="project_additional_info_container">
            <label *ngFor="let tag of selectedProject.awork_project.tags" class="lbl_box" [ngClass]="getColor(tag.colour)">{{tag.name}}</label>
          </div>
        </div>
        <div class="data_box_package">
          <h2>Zeiterfassung</h2>
          <div class="data_box_inner_flex">
            <label class="lbl_customer_data_box_data">{{selectedProject.awork_project.hours_used}}h von {{selectedProject.awork_project.budget}}h verbraucht</label>
            <label class="lbl_box" [ngClass]="selectedProject.getCapicityColorClass()">{{selectedProject.awork_project.getWorkPercentage() | number: '1.0-0'}}% verbraucht</label>
          </div>
        </div>
        <div class="data_box_package data_box_package_last">
          <button class="button_icon_lbl" (click)="selectedProject.openInAwork()">Projekt im Awork öffnen<img class="btn_label_icon" src="./../../../assets/link.svg"></button>
        </div>
      </div>
      <div class="data_box"  *ngIf="selectedProject.billing_address">
        <div class="data_box_package">
          <div class="data_box_inner_flex">
            <h2>Abweichende Rechnungsadresse</h2>
            <div class="hover_info_box">
              <img src="../../../assets/info.svg">
              <label class="lbl_box_hover">Hier ist die projektspezifische Rechnungsadresse, die ausschließlich für die Verrechnung dieses Projekts verwendet wird.</label>
            </div>
          </div>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.company_name}}</label>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.address.street}}</label>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.address.postalCode}} {{selectedProject.billing_address.address.city}}</label>
        </div>
        <div class="data_box_package data_box_package_last" *ngIf="selectedProject.billing_address.contact_person">
          <h2>Ansprechperson</h2>
          <div class="data_box_inner_flex" *ngIf="selectedProject.billing_address.contact_person?.lastName.length > 0">
            <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.contact_person?.title.name}}</label>
            <label class="lbl_box lbl_box_gray">{{selectedProject.billing_address.contact_person?.isPolite ? 'Sie' : 'Du'}}</label>
          </div>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.contact_person?.isMale ? 'Herr ' : 'Frau '}} {{selectedProject.billing_address.contact_person?.firstName}} {{selectedProject.billing_address.contact_person?.lastName}}</label>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.contact_person?.phone}}</label>
          <label class="lbl_customer_data_box_data">{{selectedProject.billing_address.contact_person?.email}}</label>
          <label *ngIf="selectedProject.billing_address.contact_person.position" class="lbl_customer_data_box_data">{{selectedProject.billing_address.contact_person?.position}}</label>
        </div>
        <div class="data_box_package" *ngIf="selectedProject.billing_address.contact_person?.lastName?.length == 0">
          <label>Kein Ansprechperson hinterlegt.</label>
        </div>
      </div>
      <div class="data_box_array">
        <h2>Ansprechpersonen</h2>
        <div class="data_box_package data_box_package_last" *ngIf="!showContactPersons()">
          <label>Keine Ansprechpersonen zugewiesen.</label>
        </div>
        <div *ngFor="let contact of selectedProject.contact_persons; let last = last">
          <div class="data_box_package" [class.data_box_package_last]="last" *ngIf="contact.contact_id != selectedProject.billing_address?.contact_person.contact_id">
            <div class="data_box_inner_flex">
              <label class="lbl_customer_data_box_data">{{contact.title?.name}}</label>
              <label class="lbl_box lbl_box_gray">{{contact.isPolite ? 'Sie' : 'Du'}}</label>
            </div>
            <label class="lbl_customer_data_box_data">{{contact.isMale ? 'Herr ' : 'Frau '}} {{contact.firstName}} {{contact.lastName}}</label>
            <label class="lbl_customer_data_box_data">{{contact.phone}}</label>
            <label class="lbl_customer_data_box_data">{{contact.email}}</label>
            <label *ngIf="contact.position" class="lbl_customer_data_box_data">{{contact.position}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div *ngIf="showSectionsProject()">
        <div *ngFor="let section of selectedProject.awork_project?.project_typ.sections; let i = index">
          <div *ngIf="showSingleSectionProject(section)" class="data_box data_box_section">
            <div class="data_box_package data_box_package_last">
              <h2>{{section.section_name}}</h2>
              <div *ngFor="let row of section.section_rows" style="width: 100%;">
                <div *ngIf="row.data_value">
                  <app-section-overview
                    [sectionRow]="row"
                  ></app-section-overview>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="data_box" [ngClass]="selectedProject.threads.length == 0 ? 'data_box_empyt_threads' : ''">
        <h2 class="h2_single">Offene Tickets</h2>
        <div *ngIf="selectedProject.threads.length == 0" id="empty_threads">
          <img src="../../../assets/empty.svg">
          <h3>Derzeit keine offenen Tickets</h3>
        </div>
        <div id="data_box_tickets_list" *ngIf="selectedProject.threads.length > 0">
          <div *ngFor="let ticket of selectedProject.threads; let last = last">
            <a *ngIf="!ticket.resolved" routerLink="/customers" [queryParams]="getTicketRouteQueryParams(ticket)" class="data_box_paragraph_ticket">
              <img class="user_img_container"
                [ngClass]="ticket.user!.picture ? 'user_img' : 'user_img_placeholder'"
                [src]="ticket.user.getAvatar()">
              <div class="ticket_column">
                <div class="ticket_column_title">
                  <div class="ticket_column_title-first-child">
                    <label class="lbl_light">#{{ticket.threadId}}</label>
                    <label class="lbl_customer_data_box_data lbl_light">{{ticket.user.firstName}} {{ticket.user.lastName}}, {{ticket.createdDate | date: 'dd MMM yyyy, HH:mm'}}</label>
                  </div>
                  <label id="lbl_customer_comments" class="lbl_customer_data_box_data lbl_light">{{ticket.messages.length > 0 ? ticket.messages.length - 1 : '0'}} Kommentare</label>
                </div>
                <div *ngIf="ticket?.subject != null || ticket.subject?.length > 0" class="lbl_customer_data_box_data">{{ticket.subject}}</div>
                <div *ngIf="ticket?.subject == null || ticket.subject?.length == 0" class="lbl_customer_data_box_data" [innerHTML]="ticket.getThreadTitle(150)"></div>
                <!-- <div class="lbl_customer_data_box_data" [innerHTML]='ticket.getThreadTitle(150)'></div> -->
                <div class="ticket_column_action_container">
                  <!-- <div class="btn_action_wrapper" (click)="null">
                    <img class="btn_action_icon" src="../../../assets/clear.svg">
                    <button class="btn_label">Auflösen</button>
                  </div> -->
                  <!-- <div class="btn_action_wrapper" (click)="null">
                    <img class="btn_action_icon" src="../../../assets/billing.svg">
                    <button class="btn_label">Verrechnen</button>
                  </div> -->
                  <img class="icon_project_nav" src="../../../assets/arrowmenucolor.svg">
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="data_box_package data_box_package_last">
          <button class="button_icon_lbl" (click)="selectionOpenCreateTicket()"><img class="btn_label_icon" src="../../../assets/addelement.svg">Neues Ticket erstellen</button>
        </div>
      </div>
    </div>
  </div>
  <div class="customer_ticket_body" *ngIf="tab == 2">
    <app-tickets-new
      [users]="users"
      [customer]="selectedCustomer"
      [project]="selectedProject"
      [isCreate]="showCreateTicket"
      (showCreateEmitter)="showCreateTicket = $event"
      (resolveEmitter)="updateTickets($event)"
    ></app-tickets-new>
    <!-- <app-customer-tickets
      [showTab]="tab"
      [showCreateTicket]="showCreateTicket"
      [currentUser]="currentUser"
      [customer]="selectedCustomer"
      [project]="selectedProject"
      [customersThreadsFiltered]="threadsFiltered"
      [selectedThread]="selectedThread"
      [users]="users"
      [minimizeSidebar]="minimizeSidebar"
      (successResolveTicketEmitter)="successResolveTicket($event)"
      (successRestoreTicketEmitter)="successRestoreTicket($event)"
      (successCreateTicketEmitter)="successCreateTicket($event)"
      (successCreateMessageEmitter)="successCreateMessageEmitter($event)"
      (selectionThreadEmitter)="selectionThread($event, false)"
      (selectionCloseThreadEmitter)="selectionCloseThread()"
      (searchTicketsEmitter)="searchTicketsAction($event)"
    ></app-customer-tickets> -->
  </div>
</div>
