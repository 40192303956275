import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { FormValidators } from 'src/app/helper/FormValidators';
import { ContactPerson } from '../../customer/ContactPerson';
import { Project } from '../Project';
import { Section, SectionRow } from '../Section';
import { formatDate } from '@angular/common';
import { DateHelper } from 'src/app/utils/DateHelper';
import { BillngAddress } from '../../customer/BillngAddress';
import { AworkProject } from '../AworkProject';
import { Address } from '../../customer/Address';

export class ProjectForm {
  static createProjectForm(formbuilder: FormBuilder, project?: Project): FormGroup {
    let form: FormGroup;
    let sectionForms: FormGroup[] = [];
    if(project){
      for(let section of project.awork_project.project_typ.sections){
        sectionForms.push(this.addSectionForm(formbuilder, section));
      }
    }
    form = formbuilder.group({
      project_id: [project?.project_id ? project?.project_id : 0, Validators.required],
      customer_id: [project?.customer_id ? project?.customer_id : 0, Validators.required],
      project_typ: [project?.awork_project.project_typ ? project?.awork_project.project_typ : null, Validators.required],
      project_name: [project?.awork_project.name ? project?.awork_project.name : null, Validators.required],
      project_description: [project?.awork_project.description ? project?.awork_project.description : null],
      add_different_address: [project?.billing_address ? true : false, Validators.required],
      street: [project?.billing_address?.address?.street ? project?.billing_address.address.street : null],
      postal_code: [project?.billing_address?.address?.postalCode ? project?.billing_address.address.postalCode : null],
      city: [project?.billing_address?.address?.city ? project?.billing_address.address.city : null],
      billing_company_name: [project?.billing_address?.company_name ? project?.billing_address.company_name : null],
      atu: [project?.billing_address?.atu_number ? project?.billing_address?.atu_number : null],
      contact_address: [project?.billing_address ? project.billing_address?.contact_person : null],
      tags: [project?.awork_project.tags ? project?.awork_project.tags : [], Validators.minLength(1)],
      //contacts: [project?.contact_persons ? project?.contact_persons : new FormArray([], FormValidators.minLengthArray(1))],
      contacts: [project?.contact_persons ? project?.contact_persons : []],
      teams: [[]],
      sections: [sectionForms],
      status: [project ? project.status : 1],
      contract_start_date: [project ? DateHelper.getFormatDateFromTimestamp(project.contract_start_date) : null, Validators.required],
      creation_date: [project ? project.creation_date : null]
    })
    if(project) form.controls['project_typ'].value.tags = project?.awork_project.tags;
    return form;
  }

  static addSectionForm(formbuilder: FormBuilder, section?: Section): FormGroup {
    return formbuilder.group({
      section_id: [section?.section_id],
      section_name: [section?.section_name ? section.section_name : '---'],
      section_rows: [section.section_rows ? this.createSectionRowForms(formbuilder,section.section_rows): []],
    })
  }

  static createSectionRowForms(formbuilder: FormBuilder, rows: SectionRow[]): FormGroup[] {
    let forms = [];
    for(let row of rows){
      forms.push(formbuilder.group({
        section_row_id: [row?.section_row_id],
        type: [row?.type ? row.type : null],
        data_value: [row?.data_value ? (row?.type == "date" ? this.getDateForForm(Number(row.data_value)) : row.data_value) : null, Validators.required],
        title: [row?.title ? row.title : null],
      }))
    }
    return forms;
  }

  static getDateForForm(timestamp: number): string {
    if(timestamp){
      // return formatDate(new Date(timestamp),'yyyy-mm-dd', 'de')
      return new Date(timestamp).toLocaleDateString();
    }
    return null;
  }

  static addValidatorsToAddress(form: FormGroup): FormGroup {
    form.controls['street'].setValidators(Validators.required);
    form.controls['postal_code'].setValidators(Validators.required);
    form.controls['city'].setValidators(Validators.required);
    form.controls['billing_company_name'].setValidators(Validators.required);
    form.controls['contact_address'].setValidators(Validators.required);
    return form;
  }

  static removeValidatorsToAddress(form: FormGroup): FormGroup {
    form.controls['street'].removeValidators(Validators.required);
    form.controls['street'].setErrors(null);
    form.controls['postal_code'].removeValidators(Validators.required);
    form.controls['postal_code'].setErrors(null);
    form.controls['city'].removeValidators(Validators.required);
    form.controls['city'].setErrors(null);
    form.controls['billing_company_name'].removeValidators(Validators.required);
    form.controls['billing_company_name'].setErrors(null);
    form.controls['contact_address'].removeValidators(Validators.required);
    form.controls['contact_address'].setErrors(null);
    return form;
  }

  static createObjectFromForm(form: FormGroup): Project {
    let billingAddress;
    if(form.controls['add_different_address'].value == true){
      billingAddress = new BillngAddress(
        form.controls['billing_company_name'].value,
        new Address(
          form.controls['street'].value,
          form.controls['postal_code'].value,
          form.controls['city'].value,
        ),
        form.controls['atu'].value,
        null,
      );
      billingAddress.contact_person = form.controls['contact_address'].value;
    }
    let aworkProject = new AworkProject(
      null,
      form.controls['project_name'].value,
      form.controls['project_description'].value,
      form.controls['project_typ'].value,
      null,
      form.controls['tags'].value,
      form.controls['teams'].value,
    );
    let sections = [];
    for(let section of form.controls['sections'].value){
      let sectionRows = [];
      for(let row of section.controls['section_rows'].value){
        sectionRows.push(new SectionRow(
          row.controls['section_row_id'].value,
          row.controls['type'].value,
          row.controls['type'].value == "date" ? (row.controls['data_value'].value && row.controls['data_value'].value != 0 ? new Date(row.controls['data_value'].value).getTime() : null) : row.controls['data_value'].value,
          row.controls['title'].value,
        ))
      }
      sections.push(new Section(
        section.controls['section_id'].value,
        section.controls['section_name'].value,
        sectionRows
      ))
    }
    let project = new Project(
      form.controls['project_id'].value,
      form.controls['customer_id'].value,
      billingAddress,
      [],
      sections,
      aworkProject,
      form.controls['status'].value,
      form.controls['creation_date'].value,
      DateHelper.getTimestampFromInputDate(form.controls['contract_start_date'].value)
    );
    return project;
  }
}
