<div class="dialog_view">
  <div class="dialog_window">
    <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
    <div>
      <div class="dialog_window_header">
        <h2>Ansprechperson</h2>
      </div>
      <div class="dialog_window_body">
        <div *ngFor="let user of usersSeller">
          <div class="checkbox_wrapper" (click)="selectionUser(user)">
            <input type="checkbox" class="checkbox" [checked]="isChecked(user)">
            <label>{{user.firstName}} {{user.lastName}}</label>
          </div>
        </div>
      </div>
    </div>
    <button class="dialog_window_btn_submit" (click)="applyFilter()">Filtern</button>
  </div>
</div>
