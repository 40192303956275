import { ProjectTag } from "../ProjectTag";

export class ProjectTagFactory {
  static jsonFactory(rawBody: any): ProjectTag[] {
    const objects: ProjectTag[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
 }

 static jsonFactoryOne(raw: any): ProjectTag {
    const req = new ProjectTag(
      raw.name,
      raw.colour
    );
    return req;
  }
}
