<div id="ticket_details_wrapper">
  <div class="details_navigation_container">
    <div class="btn_nav_wrapper" (click)="closeThread()">
      <img class="icon_arrow_left" src="../../../../assets/navarrow.svg">
      <button class="btn_label">Zurück</button>
    </div>
  </div>
  <div class="ticket_body">
    <div id="user_container">
      <img class="user_img_container"
        [ngClass]="thread.user!.picture ? 'user_img' : 'user_img_placeholder'"
        [src]="thread.user.getAvatar()">
      <div class="user_container_inner">
        <div class="lbl_regular_14">{{thread.user!.firstName}} {{thread.user!.lastName}}</div>
        <div class="lbl_customer_data_box_data" [ngStyle]="{'margin-bottom': '25px'}">{{thread.createdDate | date: 'dd. MMM YYYY, hh:mm'}}</div>
        <div class="lbl_txt" [ngStyle]="{'margin-bottom': '15px'}" [innerHTML]="getThreadTitle(thread)"></div>
        <div *ngIf="!thread.resolved" class="btn_resolve_container" [ngStyle]="{'margin-bottom': '35px'}" (click)="dissolve()">
          <img class="icon_resolve" src="../../../../assets/clear.svg">
          <button class="btn_label">Auflösen</button>
        </div>
        <div *ngIf="thread.resolved" class="btn_resolve_container" [ngStyle]="{'margin-bottom': '35px'}" (click)="restoreEmitter.emit(thread)">
          <img class="icon_resolve" src="../../../../assets/direction.svg">
          <button class="btn_label">Wiederherstellen</button>
        </div>
        <div class="lbl_regular_14">Antworten</div>
      </div>
    </div>
    <div id="answers_container">
      <div class="answer" *ngFor="let answer of answers">
        <img class="user_img_container"
                [ngClass]="answer.user?.picture ? 'user_img' : 'user_img_placeholder'"
                [src]="answer.user.getAvatar()">
        <div class="text_container">
          <div class="title_answer">
            <div class="lbl_regular_14 answer_name">{{answer.user!.firstName}} {{answer.user!.lastName}}</div>
            <div class="lbl_regular_14 answer_name" [ngStyle]="{'margin-left': 'auto'}">{{answer.timestamp | date: 'dd. MMM YYYY, hh:mm'}}</div>
          </div>
          <div class="lbl_txt" [innerHTML]="answer.message"></div>
        </div>
      </div>
    </div>
  </div>
  <app-custom-mark-input class="mark_input"
    [data]="users"
    [btn_name]="'Antworten'"
    (selectionCreateEmitter)="createMessage($event)"
  ></app-custom-mark-input>
</div>
