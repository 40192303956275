import { BillngAddressFactory } from './../../customer/BillngAddress';
import { Project } from 'src/app/buisness-object/project/Project';
import { AddressFactory } from "../../customer/factory/AddressFactory";
import { ContactPersonFactory } from "../../customer/factory/ContactPersonFactory";
import { ThreadFactory } from "../../thread/factory/ThreadFactory";
import { AworkPojectFactory } from './AworkPojectFactory';
import { SectionFactory } from './SectionFactory';
import { Section } from '../Section';

export class ProjectFactory {
  static jsonFactory(rawBody: any): Project[] {
    const objects: Project[] = [];
    for(let rawData of rawBody){
      objects.push(this.jsonFactoryOne(rawData));
    }
    return objects;
  }

  static jsonFactoryOne(raw: any): Project {
    const project = new Project(
      raw.project_id,
      raw.customer_id,
      raw.billing_address ? BillngAddressFactory.jsonFactoryOne(raw.billing_address) : null,
      raw.contact_person_ids,
      raw.sections ? SectionFactory.jsonFactory(raw.sections) : [],
      AworkPojectFactory.jsonFactoryOne(raw.awork_project),
      raw.status ? raw.status : 1,
      raw.creation_date,
      raw.contract_start_date,
    );
    project.contact_persons = raw.contact_persons ? ContactPersonFactory.jsonFactory(raw.contact_persons) : [];
    project.contact_persons.sort((a,b) => a.contact_id - b.contact_id);
    project.sections = raw.sections ? SectionFactory.jsonFactory(raw.sections) : [];
    return project;
  }
}
