import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/buisness-object/user/User';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public success$ = new BehaviorSubject<boolean>(null);

  private notification = new Subject<any>();
  notification$ = this.notification.asObservable();

  private openDialogQuery = new Subject<any>();
  openDialogQuery$ = this.openDialogQuery.asObservable();
  private closeDialogQuery = new Subject<any>();
  closeDialogQuery$ = this.closeDialogQuery.asObservable();

  private openDialogNotify = new Subject<any>();
  openDialogNotify$ = this.openDialogNotify.asObservable();
  private closeDialogNotify = new Subject<any>();
  closeDialogNotify$ = this.closeDialogNotify.asObservable();

  constructor() {
    this.success$.next(false);
  }

  showNotification(data: any) {
    this.notification.next(data);
  }

  openQuery(data: any) {
    this.openDialogQuery.next(data);
  }
  closeQuery(data: any) {
    this.closeDialogQuery.next(data);
  }

  openNotify(data: any) {
    this.openDialogNotify.next(data);
  }
  closeNotify(data: any) {
    this.closeDialogNotify.next(data);
  }

  getTitle(): string {
    switch (this.getRandomNumber(1,6)) {
      case 1: return 'Erfolgreich'; break;
      case 2: return 'Sehr gut!'; break;
      case 3: return 'Weiter so!'; break;
      case 4: return 'Da ist heute aber jemand sehr fleißig!'; break;
      case 5: return 'Schoki verdient!'; break;
      case 6: let userID = Number(localStorage.getItem("user_id"));
          if(userID == 44) return 'In God-Mode?';
          else if(userID == 47) return 'Gönn dir ein Gläschen Aperol verdient!';
          else return 'Niiiiice';
          break;
      default: return 'Erfolgreich'; break;
    }
  }
  getPfui(): string {
    switch (this.getRandomNumber(1,5)) {
      case 1: return 'Erfolgreich'; break;
      case 2: return 'Pfui'; break;
      case 3: return 'Doch noch Arbeit?'; break;
      case 4: return 'Es wird schon werden. Kopf hoch!'; break;
      case 5: return 'Erfolgreich'; break;
      default: return 'Erfolgreich'; break;
    }
  }

  private getRandomNumber(min: number, max:number): number {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  handleError(error: any) {
    console.log(error)
    if(error?.status == 0){
      this.showNotification({title:'Fehlgeschlagen',message:error.statusText,success:false});
    } else {
      this.showNotification({title:'Fehlgeschlagen',message:error.error.error_message,success:false});
    }
  }

  setSuccess() {
    this.success$.next(true);
  }

  canActivate(): boolean {
    return this.success$.getValue();
  }
}
