import { Thread } from "../Thread";
import { MessageFactory } from "./MessageFactory";

export class ThreadFactory {
    static jsonFactory(rawBody: any): Thread[] {
        const threads: Thread[] = [];
        for(let rawThread of rawBody){
            threads.push(this.jsonFactoryOne(rawThread));
        }
        return threads;
    }

    static jsonFactoryOne(rawThread: any): Thread {
        const thread = new Thread(
            rawThread.thread_id,
            rawThread.customer_id,
            rawThread.user_id,
            rawThread.typ,
            rawThread.status,
            rawThread.pinned,
            rawThread.notify_open,
            rawThread.created,
            rawThread.resolved,
            rawThread.typ_id,
            rawThread.subscribed,
            rawThread.resolved_by_user_id,
            rawThread.project_id,
        );
        thread.messages = MessageFactory.jsonFactory(rawThread.messages);
        thread.subject = rawThread.subject;
        return thread;
    }
}
